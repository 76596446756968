import translator from 'i18next'

import { TBiddingStrategy } from '../../../core/type'
import Axios from '../../../core/axios'
import { fromEuroCents, toEuroCents } from './TenantStrategiesService'

export interface IStrategy {
  id: number
  isDefault: boolean
  bidStrategy: string
  parameter?: string
  bidValue: number
}

const StrategyParameters = {
  CPC: translator.t('tenants.budgets.optimization.cpcParameter'),
  CPM: translator.t('tenants.budgets.optimization.cpmParameter'),
  CONVERSIONS: translator.t('tenants.budgets.optimization.conversionsParameter'),
}

export class TenantStrategyAPI {
  static async get(campaignId: number | string) {
    try {
      const { data } = await Axios.get(`/tenants/${campaignId}/bid-strategies`, {
        headers: { Accept: 'application/json' },
      })

      const { 'hydra:member': packages } = data

      return TenantStrategySerializer(packages)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while getting strategy')

      return Promise.reject(error)
    }
  }

  static async setDefaultStrategy(strategyId: number | string) {
    try {
      return Axios.put(`tenant-bid-strategies/${strategyId}`, { id: strategyId, isDefault: true })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while put tenant strategy')

      return Promise.reject(error)
    }
  }

  static updateValue(strategyId: number | string, { bidValue }: { bidValue: number }) {
    try {
      return Axios.put(`tenant-bid-strategies/${strategyId}`, { bidValue: toEuroCents(bidValue) })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while put tenant strategy')

      return Promise.reject(error)
    }
  }
}

const TenantStrategySerializer = (strategies: any) => {
  return strategies.map((strategy: any) => {
    let result = {
      ...strategy,
    }

    if (strategy.bidStrategy === TBiddingStrategy.CPC) {
      result.parameter = StrategyParameters.CPC
      result.bidValue = fromEuroCents(result.bidValue) || 0
    }

    if (strategy.bidStrategy === TBiddingStrategy.CPM) {
      result.parameter = StrategyParameters.CPM
      result.bidValue = fromEuroCents(result.bidValue) || 0
    }

    if (strategy.bidStrategy === TBiddingStrategy.MAX_CLICKS) {
      result.bidValue = null
    }

    return result
  })
}
