import AMPHTMLZIPParser from './AMPHTMLZIPParser'
import {parse} from 'node-html-parser'
import type {AMPHTMLParseResult} from '../../../pages/Campaigns/new/steps/preview/banner/amphtml'
import type { HTMLElement as ParserHTMLElement } from 'node-html-parser'
import {groupBy} from  'lodash'
import { toPng, toBlob } from 'html-to-image'

const convertObjectToStyles = (object: Record<any, any>) => {
  let styles = ''

  for (let prop in object) {
    styles += `${prop}: ${object[prop]}`
  }

  return styles
}

const PROPS_TO_REMOVE = ['-moz-animation-timing-function', '-webkit-animation-timing-function', 'animation-timing-function', '-moz-animation',
  '-webkit-animation', 'animation', '-moz-webkit-animation', '-webkit-webkit-animation', 'webkit-animation', '-moz-animation',
  '-webkit-animation', 'animation', '-moz-animation-duration', '-webkit-animation-duration', 'animation-duration', '-moz-animation-delay',
  '-webkit-animation-delay', 'animation-delay', '-moz-animation-iteration-count', '-webkit-animation-iteration-count', 'animation-iteration-count',
  '-moz-animation-direction', '-webkit-animation-direction', 'animation-direction', '-moz-animation-fill-mode', '-webkit-animation-fill-mode', 'animation-fill-mode',
  '-moz-animation-play-state', '-webkit-animation-play-state', 'animation-play-state', '-moz-animation-name', '-webkit-animation-name', 'animation-name',
  '-moz-animation-timeline', '-webkit-animation-timeline', 'animation-timeline', '-moz-animation-range-start', '-webkit-animation-range-start', 'animation-range-start',
  '-moz-animation-range-end', '-webkit-animation-range-end', 'animation-range-end']

class AMPHTMLIFrameTransformer {
  parseResult: AMPHTMLParseResult = {
    html: '',
    height: -1,
    width: -1,
  }
  AMPDocument: ParserHTMLElement = parse('<html></html>')

  constructor(private AMPHTMLZip: File) {}

  public async getPNGBlob() {
    try {
      this.parseResult = await new AMPHTMLZIPParser(this.AMPHTMLZip).transform()

      this.proceedStaticHTML()
      this.parseStaticHTML()
      await this.proceedAnimations()

      
      return this.convertToPNGBlob()

    } catch (e) {
      console.error(e)

      return null
    }
  }

  private proceedStaticHTML () {
    this.parseResult.html = this.parseResult.html.replace(/amp-img/g, 'img')
  }

  private parseStaticHTML() {
    this.AMPDocument = parse(this.parseResult.html)
  }

  private async proceedAnimations() {
    this.proceedGWDAnimationsAttributes()
    this.proceedAMPCustom()
    this.removeAMPBoilerplateStyle()
    await this.processDocumentStyles()
    this.removeAllUnhandledStyles()
    this.proceedScripts()
  }

  private removeAMPBoilerplateStyle() {
    this.AMPDocument
      .querySelectorAll('style[amp4ads-boilerplate],style[amp-runtime]')
      .forEach(node => node.remove())
  }

  private getStylesFromCSSRules(cssRule: CSSStyleDeclaration) {
    const rules:Record<string, any> =  {}
    const declaredProps = []

    if(!cssRule?.length){
      console.warn(cssRule)
    }

    for(let i = 0; i < cssRule?.length; i++) {
      declaredProps.push(cssRule[i])
    }

    declaredProps.forEach(prop => {
      // @ts-ignore
      rules[prop] = cssRule?.style?.[prop] || cssRule[prop]
    })

    return rules
  }

  private proceedKeyframeRule(keyframesRule: CSSKeyframesRule) :  [string, Record<string, string>] {
    const name = keyframesRule.name
    const lastKeyFrameRule:CSSRule = [...keyframesRule.cssRules].at(-1)!
    // @ts-ignore
    const rules:Record<string, any> =  this.getStylesFromCSSRules(lastKeyFrameRule.style)

    return [name,rules]
  }


  private proceedCssRule(cssRule: CSSStyleRule) : [string, Record<string, string>] {
    const name = cssRule.selectorText
    const rules:Record<string, any> =  this.getStylesFromCSSRules(cssRule.style)

    return [name,rules]
  }


  private proceedScripts() {
    const documentScripts = this.AMPDocument.getElementsByTagName('script')
    documentScripts.map((script) => {
      // if (script.hasAttribute('type')) {
      //   return
      // }
      script.remove()
    })

    this.AMPDocument.querySelectorAll('amp-animation script').forEach(script => script.remove())

  }

  private getCSSRulesFromStylesheet(stylesheet:CSSStyleSheet):CSSRule[] {
    if(stylesheet.href) {
      return []
    } else {
      return [...stylesheet.cssRules]
    }
  }

  private async processDocumentStyles() {
    this.AMPDocument
      .querySelectorAll('style[amp-keyframes]')
      .forEach(style => {
        style.removeAttribute('amp-keyframes')
        /* means style was processed by this specific method*/
        style.setAttribute('data-processed','true')
      })


    const documentStyles:Array<[string, Record<string, string>]> = await new Promise((res) => {
      const fakeIframe = document.createElement('iframe')
      fakeIframe.srcdoc = this.AMPDocument.innerHTML
      document.body.appendChild(fakeIframe)

      fakeIframe.addEventListener('load', () => {
        const styles:Array<[string, Record<string, string>]> = []
        const CSSRules = [...fakeIframe.contentDocument!.styleSheets].flatMap(stylesheet => this.getCSSRulesFromStylesheet(stylesheet))
        const groupedCSSRules = groupBy(CSSRules, item => item.constructor.name === 'CSSKeyframesRule')
        const keyFramesRules= (groupedCSSRules['true'] || []) as Array<CSSKeyframesRule>
        const styleRules= (groupedCSSRules['false'] || []) as Array<CSSStyleRule>

        keyFramesRules.forEach(rule => {
          const rules = this.proceedKeyframeRule(rule)

          styles.push(rules)
        })

        styleRules.forEach(rule => {
          const rules = this.proceedCssRule(rule)

          styles.push(rules)
        })

        fakeIframe.remove()

        res(styles)
      } )
    })

    const stylesWithInsertedAnimation = this.insertAnimationPropsToCSSClasses(documentStyles)
    const stylesWithoutAnimation = this.removeAnimationProps(stylesWithInsertedAnimation)
    const validCSS = this.makeValidCSS(stylesWithoutAnimation)
    const stylesHTML = `<style injected="true">${validCSS}</style>`
    const body = this.AMPDocument.querySelector('body')!

    body.innerHTML = stylesHTML + body?.innerHTML
  }

  private insertAnimationPropsToCSSClasses(documentStyles: Array<[string, Record<string, string>]>): Array<[string, Record<string, string>]> {
    return documentStyles.map(cssDeclaration => {
      const [selector, styles] = cssDeclaration

      if('animation-name' in styles) {
        const matchedKeyFrames = documentStyles.filter(([keyFramesName]) => keyFramesName === styles['animation-name'])
        const keyframesStylesSummary = matchedKeyFrames.reduce((cumulative,[,keyframeStyles]) => {
          return {...cumulative, ...keyframeStyles}
        }, {} )


        return [selector, {...cssDeclaration[1], ...keyframesStylesSummary}]
      }

      return cssDeclaration

    })
  }

  private removeAnimationProps(documentStyles: Array<[string, Record<string, string>]>): Array<[string, Record<string, string>]>  {
    return documentStyles.map(documentStylesEntry => {
      const [selector, styles] = documentStylesEntry

      const propsToKeep = Object.entries(styles)
        .filter(([prop, value]) => {

          if(selector.includes('amphtml') && prop === 'display' && value === 'none') {
            return false
          }

          return !PROPS_TO_REMOVE.includes(prop) /* amp initial stuff*/
        })


      return [selector, Object.fromEntries(propsToKeep)]
    })

  }

  private preprocessStylesEntry(entry: [string, string]) {
    const [key, value] = entry

    if(key === 'transform' && value.includes('translate3d')) {
      const match = value.matchAll(/translate3d\((-?\d\w+),\s?(-?\d\w+),\s?(-?\d\w+)\)/gi)
      const [,x,y] = [...match][0]
      const newValue = `translate(${x},${y})`

      return [key, newValue]
    }

    return entry
  }

  private stringifyStylesEntry(styles: Record<string, string>) :string {
    return Object.entries(styles)
      .map(entry => this.preprocessStylesEntry(entry))
      .reduce((stringStyles, [prop, value]) => {
      return `${stringStyles}${prop}: ${value};`
    }, '')
  }

  private makeValidCSS(documentStyles: Array<[string, Record<string, string>]>): string  {
    return documentStyles.reduce((css,documentStylesEntry) => {
      const [selector, styles] = documentStylesEntry

      return `${css}${selector} {${this.stringifyStylesEntry(styles)}}`
    }, '')
  }

  private proceedGWDAnimationsAttributes() {
    this.AMPDocument.querySelectorAll('*[data-gwd-animation-labels]')
      .forEach(element => {
        const gwdValue = element.getAttribute('data-gwd-animation-labels')!
        const [className] = gwdValue.split(',')

        element.classList.add(className)
        element.removeAttribute('data-gwd-animation-labels')
      })

  }

  private proceedAMPCustom() {
    const ampAnimations = this.AMPDocument.querySelectorAll('amp-animation script')
    const animationJSONs = ampAnimations.map((script) => script.innerHTML)
    const stylesObjects = animationJSONs.map((json) => JSON.parse(json)).flat()

    const selectorsWithKeyframes = stylesObjects.filter((style) => !!style.keyframes?.length)
    const selectorsWithLastFrame = selectorsWithKeyframes.map(({ selector, keyframes }) => ({
      selector,
      keyframes: keyframes.at(-1),
    }))

    const stringStyles = selectorsWithLastFrame.map(({ selector, keyframes }) => {
      return `${selector} {${convertObjectToStyles(keyframes)}}`
    })

    this.AMPDocument.querySelectorAll('style[amp-custom]')
      .map((style) => {
        style.removeAttribute('amp-custom')
        /* means style was processed by this specific method*/
        style.setAttribute('data-processed','true')
        style.insertAdjacentHTML('beforeend', stringStyles.join(''))
    })
  }

  private removeAllUnhandledStyles() {
    /* remaining styles usually just hide content initially  */
    this.AMPDocument.querySelectorAll('style')
      .forEach(style => {
        if (!style.hasAttribute('injected')) {
          style.remove()
        }
      })
  }

  async convertToPNGBlob() {
    const initialDocumentOverflow = document.body.style.overflow
    document.body.style.overflow = 'hidden'

    const renderNode = document.createElement('div')
    document.body.appendChild(renderNode)

    renderNode.innerHTML = this.AMPDocument.querySelector('body')?.innerHTML!
    renderNode.style.width = `${this.parseResult.width}px`
    renderNode.style.height = `${this.parseResult.height}px`

    const [image, blob] = await Promise.all([toPng(renderNode),toBlob(renderNode) ])

    // @ts-ignore
    if(process.env.NODE_ENV === 'development' || window?.development) {

      // base64 from AMPHTML testing code
      const newImage = document.createElement('img')
      newImage.src = image
      document.body.appendChild(newImage)

      ///// blob
      var urlCreator = window.URL || window.webkitURL
      var imageUrl = urlCreator.createObjectURL(blob!)
      const newImage2 = document.createElement('img')
      newImage2.src = imageUrl
      document.body.appendChild(newImage2)
    }

    document.body.style.overflow = initialDocumentOverflow

    renderNode.remove()

    return blob
  }
}

export default AMPHTMLIFrameTransformer
