import translate from 'i18next'
import * as Yup from 'yup'
import type {AddedImage, IImage} from '../../../../../../common/components/Images/types'
import { IMAGE_GROUP, IMAGE_TYPE} from '../../../../../../common/components/Images/types'

export const MAX_ALLOWED_LOGOS = 5
export const MAX_ALLOWED_IMAGES = 15
export const MIN_ALLOWED_IMAGES = 1

export const filterByGroup = (images: Array<IImage | AddedImage>, group: IMAGE_GROUP) =>
  images.filter((image) => image.group === group)

export const filterByType = (images: Array<IImage | AddedImage>, type: IMAGE_TYPE) =>
  images.filter((image) => image.type === type)

export default Yup.object().shape({
  images: Yup.array()
    .test(
      'images',
      translate.t('campaigns.validation.maximumImagesError', {
        allowed: MAX_ALLOWED_LOGOS,
      }),
      (value) => filterByGroup(value as Array<IImage | AddedImage>, IMAGE_GROUP.IMAGE).length <= MAX_ALLOWED_IMAGES
    )
    .test(
      'images',
      translate.t('campaigns.validation.atLeastOneShouldBeSet', {
        resolution: '1:1',
      }),
      (value) =>
        filterByType(filterByGroup(value as Array<IImage | AddedImage>, IMAGE_GROUP.IMAGE), IMAGE_TYPE.SQUARE).length >=
        MIN_ALLOWED_IMAGES
    )
    .test(
      'images',
      translate.t('campaigns.validation.atLeastOneShouldBeSet', {
        resolution: '1.91:1',
      }),
      (value) =>
        filterByType(filterByGroup(value as Array<IImage | AddedImage>, IMAGE_GROUP.IMAGE), IMAGE_TYPE.HORIZONTAL)
          .length >= MIN_ALLOWED_IMAGES
    ),
})
