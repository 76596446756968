import { createSlice } from '@reduxjs/toolkit'
import { TenantAPI } from 'api/tenants/tenants'
import { setSnackbar } from 'core/store/reducers/snackbar'
import type { AppDispatch, RootState } from 'core/store/store'
import type { AdServerTypes, BudgetTypes } from 'core/type'

export interface EditableRow {
  isEdited: boolean
}

export interface Budget {
  id: number
  server: AdServerTypes
  type: BudgetTypes
  budget: number
  marginPercentage: number
  createdAt: string
  updatedAt: string
}

interface BudgetsInitialState {
  budgets: Array<Budget & EditableRow>
  isLoading: boolean
}

export const initialState: BudgetsInitialState = {
  budgets: [] as Array<Budget & EditableRow>,
  isLoading: false,
}

const budgets = createSlice({
  name: 'budgets',
  initialState,
  reducers: {
    budgetsLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    budgetsError: (state) => {
      state.isLoading = false
    },
    budgetsSuccess: (state, { payload }) => {
      state.budgets = payload
      state.isLoading = false
    },
    addBudget: (state, { payload }) => {
      state.budgets = [...state.budgets, payload]
    },
    deleteBudget: (state, { payload }) => {
      state.budgets = state.budgets.filter(({ id }) => id !== payload.id)
    },
    updateBudget: (state, { payload }) => {
      state.budgets = state.budgets.map((budget) => {
        if (budget.id === payload.id) {
          budget.marginPercentage = payload.marginPercentage
          budget.budget = payload.budget
          budget.isEdited = false
        }

        return budget
      })
    },
    editBudget: (state, { payload }) => {
      state.budgets = state.budgets.map((budget) => {
        if (budget.id === payload.id) {
          budget.isEdited = payload.isEdited
        }

        return budget
      })
    },
    resetBudgets: () => initialState,
  },
})

export const budgetsIsLoadingSelector = (state: RootState) => state.budgets.isLoading

export const getBudgetsByServerAndType =
  (state: RootState) => (requiredServer: AdServerTypes, requiredType: BudgetTypes) => {
    const entities = state.budgets.budgets.filter(
      ({ type, server }) => requiredServer === server && requiredType === type
    )

    const budgets = entities.map(({ budget }) => budget)

    return {
      entities,
      budgets,
    }
  }

export const {
  addBudget,
  budgetsError,
  budgetsLoading,
  budgetsSuccess,
  deleteBudget,
  updateBudget,
  resetBudgets,
  editBudget,
} = budgets.actions

export default budgets.reducer

export const getTenantBudgets = (tenantId: number | string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(budgetsLoading(true))

    try {
      const budgetsData = await TenantAPI.getTenantBudgets(tenantId)
      dispatch(budgetsSuccess(budgetsData))
    } catch (error) {
      dispatch(budgetsError())
      dispatch(setSnackbar(true, 'error', 'Failed to load tenant budgets'))
    }
  }
}
