// @ts-ignore
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NoImage from 'assets/icons/no_image_native_mobile_square.png'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { filterByGroup, filterByType } from '../../advertising/native/schema'
import { Mobile } from '../devices/Mobile'
import type { IEnhancedCampaign } from './DesktopSquare'
import type {AddedImage, IImage} from '../../../../../../common/components/Images/types'
import { IMAGE_GROUP, IMAGE_TYPE} from '../../../../../../common/components/Images/types'

const styles = makeStyles(() => ({
  headline: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'start',
  },
  description: {
    fontWeight: 400,
    fontSize: 13,
    textAlign: 'start',
    fontFamily: 'Source Sans Pro',
    whiteSpace: 'pre-wrap',
  },
  businessName: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'start',
    fontFamily: 'Source Sans Pro',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}))

const CreateMobileSquare = ({ campaign, activeImageIndex }: IEnhancedCampaign) => {
  const classes = styles()
  const { headline, description, businessName } = campaign.ad
  const image = filterByType(
    filterByGroup(campaign.images as Array<IImage | AddedImage>, IMAGE_GROUP.IMAGE),
    IMAGE_TYPE.SQUARE
  )[activeImageIndex]
  const { t } = useTranslation()

  useEffect(() => {
    const screen = document.querySelector('.screen')

    if (screen) {
      screen.setAttribute('style', 'overflow: hidden;background: white;')

      const screenChild = screen.querySelector('div')

      if (screenChild) {
        screenChild.setAttribute('style', 'position: absolute;width: 100%;')
      }
    }
  })

  return (
    <Mobile>
      <Box pl={4} pr={4}>
        <img alt={'native preview'} src={image?.url || NoImage} />

        <Box mt={4} mb={4}>
          <Typography variant='subtitle2' color='textPrimary' className={classes.headline}>
            {headline || t('campaigns.preview.addHeadline')}
          </Typography>
          <Box mt={2}>
            <Typography variant='subtitle2' color='textPrimary' className={classes.description}>
              {description || t('campaigns.preview.addDescription')}
            </Typography>
          </Box>
          <Box mt={3}>
            <Typography variant='subtitle2' color='textPrimary' className={classes.businessName}>
              {businessName || t('campaigns.preview.addBusinessName')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Mobile>
  )
}

export default CreateMobileSquare
