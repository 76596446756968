import type { AnyAction } from '@reduxjs/toolkit'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import snackbar from 'core/store/reducers/snackbar'
import adServers from 'core/store/reducers/adServers'
import appearance from 'core/store/reducers/appearance'
import auth from 'core/store/reducers/auth'
import notes from 'core/store/reducers/noteReducer'
import campaigns from 'core/store/reducers/campaigns'
import campaign from 'core/store/reducers/campaign'
import client from 'core/store/reducers/clientReducer'
import clients from 'core/store/reducers/clientsReducer'
import strategies from 'core/store/reducers/strategyReducer'
import budgets from 'core/store/reducers/budgets'
import dropdownTenants from 'core/store/reducers/dropdownTenants'
import targetImpressions from 'core/store/reducers/targetImpessions'
import tenant from 'core/store/reducers/tenant'
import tenantAppearance from 'core/store/reducers/tenantAppearance'
import tableTenants from 'core/store/reducers/tenants'
import user from 'core/store/reducers/userReducer'
import users from 'core/store/reducers/usersReducer'

import { errorHandlingModal } from './reducers/store'
import { LocalStorage } from '../localStorage'

const rootReducer = combineReducers({
  auth,
  campaigns,
  tableTenants,
  dropdownTenants,
  tenant,
  tenantAppearance,
  client,
  clients,
  campaign,
  users,
  user,
  appearance,
  adServers,
  budgets,
  targetImpressions,
  snackbar,
  notes,
  strategies,
  errorHandlingModal,
})

export type RootState = ReturnType<typeof rootReducer>

const resettableRootReducer = (state?: ReturnType<typeof rootReducer>, action?: AnyAction) => {
  if (action?.type === 'LOGOUT') {
    LocalStorage.remove('token')

    return rootReducer(undefined, action)
  }

  return rootReducer(state, action)
}

export const initStore = () =>
  configureStore({
    reducer: resettableRootReducer,
    devTools: true,
  })

const store = initStore()
export type AppDispatch = typeof store.dispatch
export default store
