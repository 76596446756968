import pageHistory from 'core/history'
import type { FC } from 'react'
import { createContext, useCallback, useEffect, useRef } from 'react'

type HistoryObject = { pathname: string; search: string; state?: { [key: string]: any } }

export const HistoryItems = createContext<{
  locations: Array<HistoryObject>
  isEqualToLast: (history: HistoryObject) => boolean
}>({ locations: [], isEqualToLast: () => false })

const HistoryItemsProvider: FC = ({ children }) => {
  const { current: locations } = useRef<Array<HistoryObject>>([])

  useEffect(() => {
    const removeListener = pageHistory.listen((e) => {
      const lastEntry = locations[locations.length - 1]
      const last = lastEntry?.pathname + lastEntry?.search
      const current = e.pathname + e.search

      if (last !== current) {
        locations.push({
          pathname: e.pathname,
          search: e.search,
          state: e.state as any,
        })
      }
    })

    return () => removeListener()
  }, [locations])

  const isEqualToLast = useCallback(
    ({ pathname: newPathname, search: newSearch }: HistoryObject) => {
      const lastHistoryObject = locations[locations.length - 1]

      return lastHistoryObject?.search === newSearch && lastHistoryObject?.pathname === newPathname
    },
    [locations]
  )

  return (
    <HistoryItems.Provider
      value={{
        locations,
        isEqualToLast,
      }}
    >
      {children}
    </HistoryItems.Provider>
  )
}

export default HistoryItemsProvider