import { Box, Divider, Grid, IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandLessRounded, ExpandMoreRounded, PersonRounded } from '@material-ui/icons'
import { ReactComponent as Logo } from 'assets/icons/adonce_main_logo_white_text.svg'
import { ReactComponent as Help } from 'assets/icons/help.svg'
import { ReactComponent as Key } from 'assets/icons/key.svg'
import { ReactComponent as Logout } from 'assets/icons/logout.svg'
import { ReactComponent as Profile } from 'assets/icons/profile.svg'
import clsx from 'clsx'
import { getCurrentLocalePath } from 'core/router/hooks/useLocales'
import { appearanceSelector } from 'core/store/reducers/appearance'
import { loggedUserSelector, logoutAction } from 'core/store/reducers/auth'
import { selectedTenantSelector } from 'core/store/reducers/dropdownTenants'
import type { MouseEventHandler } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Routes } from 'core/router/routes'

import { historyPushWithLocale } from '../../../core/history'
import { LinkUnstyled } from '../Links/LinkUnstyled'
import { NavigationButtons } from '../Navigation'
import { TenantSelector } from '../TenantSelector'
import useStyles from './styles'

const Header = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const user = useSelector(loggedUserSelector)
  const selectedTenant = useSelector(selectedTenantSelector)
  const { isCustomLogo, customLogoUrl } = useSelector(appearanceSelector)
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const handleClick: MouseEventHandler = (event) => {
    setAnchorEl(event.currentTarget as Element)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logout = async () => {
    dispatch(logoutAction())
    historyPushWithLocale(Routes.ROOT)
  }

  const campaignDefaultQueryParams = `?itemsPerPage=10&order[createdAt]=desc&page=1${
    selectedTenant ? `&tenant[]=${selectedTenant.id}` : ''
  }`

  return (
    <Box className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item>
          <Grid container>
            <Grid item>
              <Box mt={2} mr={6}>
                <Link to={getCurrentLocalePath() + Routes.CAMPAIGNS + encodeURI(campaignDefaultQueryParams)}>
                  {isCustomLogo && customLogoUrl ? (
                    <img
                      data-testid={'custom-logo'}
                      alt={'User logo'}
                      src={customLogoUrl}
                      className={classes.customLogo}
                    />
                  ) : (
                    <Logo width='125px' height='24px' />
                  )}
                </Link>
              </Box>
            </Grid>
            <Grid item>
              <NavigationButtons />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.selectorConteiner}>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Box display={'flex'} alignItems={'center'} mr={3}>
              <LinkUnstyled to={Routes.HELP}>
                <IconButton>
                  <Help fill={window.location.pathname.includes(Routes.HELP) ? 'white' : '#8B918D'} />
                </IconButton>
              </LinkUnstyled>
            </Box>
            <Box mr={3}>
              <TenantSelector />
            </Box>
          </Box>
          <Box
            display='flex'
            alignItems='center'
            justifyContent={'flex-end'}
            mr={'-5px'}
            ml={3}
            pr={1}
            pl={1}
            width='auto'
            className={clsx(classes.userMenuContainer, anchorEl ? classes.activeUserMenu : null)}
            onClick={handleClick}
          >
            <IconButton size='small'>
              <PersonRounded className={classes.secondaryDimmed} />
            </IconButton>
            <Box mr={1} />
            <Typography className={classes.userName} variant='subtitle1'>
              {user.name}
            </Typography>
            {anchorEl ? (
              <ExpandLessRounded className={classes.whiteColor} />
            ) : (
              <ExpandMoreRounded className={classes.whiteColor} />
            )}
          </Box>
          <Menu
            style={{ top: 31 }}
            id='simple-menu'
            anchorEl={anchorEl!}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                historyPushWithLocale(Routes.PROFILE)
                handleClose()
              }}
              className={classes.menuItem}
            >
              <Box mr={2}>
                <IconButton size='small'>
                  <Profile width='18px' height='18px' />
                </IconButton>
              </Box>
              {t('header.menu.editProfile')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                historyPushWithLocale(Routes.PROFILE_PASSWORD_CHANGE)
                handleClose()
              }}
              className={classes.menuItem}
            >
              <Box mr={2}>
                <IconButton size='small'>
                  <Key width='18px' height='18px' />
                </IconButton>
              </Box>
              {t('header.menu.changePassword')}
            </MenuItem>
            <Divider />
            <Box mt={1} />
            <MenuItem onClick={logout} className={classes.menuItem}>
              <Box mr={2}>
                <IconButton size='small'>
                  <Logout width='18px' height='18px' />
                </IconButton>
              </Box>
              {t('header.menu.logout')}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Header
