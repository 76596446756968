import { Box, Dialog, Typography } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Routes } from '../../../core/router/routes'
import { errorHandlingModalSelector, hideModal } from '../../../core/store/reducers/store'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  modal: {
    maxWidth: 700,
  },
  description: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.88)',
    letterSpacing: '0.5px',
    fontStyle: 'normal',
    fontWeight: 400,
    display: 'block',
    textDecoration: 'inherit',
  },
  link: {
    color: theme.palette.primary.main,
  },
}))

const ERRORS = {
  400: {
    header: 'Bad request',
    body: 'The server cannot or will not process the request due to something that is perceived to be a client error (e.g., malformed request syntax, invalid request message framing, or deceptive request routing).',
  },
  401: {
    header: 'Unauthorized',
    body: 'The request has not been applied because it lacks valid authentication credentials for the target resource.',
  },
  402: {
    header: 'PAYMENT REQUIRED',
    body: 'Reserved for future use.',
  },
  403: {
    header: 'Forbidden',
    body: 'The server understood the request but refuses to authorize it.',
  },
  404: {
    header: 'Not found',
    body: 'The origin server did not find a current representation for the target resource or is not willing to disclose that one exists.',
  },
  405: {
    header: 'Method not allowed',
    body: 'The method received in the request-line is known by the origin server but not supported by the target resource.',
  },
  500: {
    header: 'Internal server error',
    body: 'The server encountered an unexpected condition that prevented it from fulfilling the request.',
  },
  501: {
    header: 'Not implemented',
    body: 'The server does not support the functionality required to fulfill the request.',
  },
  502: {
    header: 'Bad gateway',
    body: 'The server, while acting as a gateway or proxy, received an invalid response from an inbound server it accessed while attempting to fulfill the request.',
  },
  503: {
    header: 'Service unavailable',
    body: 'The server is currently unable to handle the request due to a temporary overload or scheduled maintenance, which will likely be alleviated after some delay.',
  },
  504: {
    header: 'Gateway timeout',
    body: 'The server, while acting as a gateway or proxy, did not receive a timely response from an upstream server it needed to access in order to complete the request.',
  },
  505: {
    header: 'HTTP version not supported',
    body: 'The server does not support, or refuses to support, the major version of HTTP that was used in the request message.',
  },
  'Network Error': {
    header: 'No interner connection',
    body: 'Check your connection or try this later',
  },
  'Unknown Error': {
    header: 'Unknown error occurred',
    body: 'We are trying to fix this',
  },
}

const useDialogStyles = makeStyles(() => ({
  scrollPaper: {
    alignItems: 'flex-start',
    marginTop: 300,
  },
}))

const ErrorHandingModal = () => {
  const { modal, root, description, link } = useStyles()
  const dialogStyles = useDialogStyles()
  const dispatch = useDispatch()
  // @ts-ignore
  const { isShown, code } = useSelector(errorHandlingModalSelector)
  const handleClose = () => {
    dispatch(hideModal())
  }

  const { body, header } = ERRORS[code.toString() as keyof typeof ERRORS] || ERRORS['Unknown Error']

  return (
    <>
      {isShown && (
        <div className={root}>
          {code && (
            <Dialog classes={dialogStyles} onClose={handleClose} open={isShown}>
              <Box className={modal} pt={8} pb={6} pl={10} pr={10}>
                <Typography variant='h4'>{header}</Typography>
                {/*<Typography variant='h4' >{ t(`errorHandling.${code}.header`) }</Typography>*/}
                <Box mb={4} />
                <Typography variant='caption' className={description}>
                  {body}
                  {/*{ t(`errorHandling.${code}.body`) }*/}
                </Typography>
                <Box mt={4} display={'flex'}>
                  <Link
                    className={link}
                    to={Routes.ROOT}
                    // style={ { display: 'block', textDecoration: 'inherit', } }
                    onClick={handleClose}
                  >
                    <Typography variant={'subtitle1'}>Visit home page</Typography>
                  </Link>
                  <Box ml={1} mr={1}>
                    <Typography className={description} variant={'caption'}>
                      {' or '}
                    </Typography>
                  </Box>

                  <Link
                    className={link}
                    to={Routes.ROOT}
                    // style={ { display: 'block', textDecoration: 'inherit', } }
                    onClick={handleClose}
                  >
                    <Typography variant={'subtitle1'}>Get help</Typography>
                  </Link>
                </Box>
              </Box>
            </Dialog>
          )}
        </div>
      )}
    </>
  )
}

export default ErrorHandingModal