import { createSlice } from '@reduxjs/toolkit'

import type { IStrategy } from '../../../api/bidStrategies/tenant/TenantStrategyAPI'
import { TenantStrategyServiceAPI } from '../../../api/bidStrategies/tenant/TenantStrategiesService'
import { setSnackbar } from './snackbar'
import { TBiddingStrategy } from '../../type'
import type { AppDispatch, RootState } from '../store'

export interface EditableRow {
  isEdited: boolean
}

export type EditStrategy = {
  bidStrategy: string | null
  bidValue: number | string
}

interface IStrategies {
  strategies: Array<IStrategy & EditableRow>
  isLoading: boolean
}

const initialState: IStrategies = {
  strategies: [] as Array<IStrategy & EditableRow>,
  isLoading: false,
}

const noteReducer = createSlice({
  name: 'strategies',
  initialState,
  reducers: {
    putStrategies: (state, { payload }) => {
      state.strategies = payload
    },
    strategyLoading: (state) => {
      state.isLoading = true
    },
    strategyLoaded: (state) => {
      state.isLoading = false
    },
    updateStrategies: (state, { payload }) => {
      state.strategies = state.strategies.map((strategy) => {
        strategy.isDefault = strategy.id === payload

        return strategy
      })
      state.isLoading = false
    },
    updateStrategyValue: (state, { payload }) => {
      state.strategies = state.strategies.map((strategy) => {
        if (payload.id === strategy.id) {
          strategy.bidValue = payload.bidValue
        }

        return strategy
      })
    },
    editStrategy: (state, { payload }) => {
      state.strategies = state.strategies.map((strategy) => {
        if (strategy.id === payload.id) {
          strategy.isEdited = payload.isEdited
        }

        return strategy
      })
    },
  },
})

export default noteReducer.reducer

export const { editStrategy, updateStrategies, putStrategies, updateStrategyValue, strategyLoading, strategyLoaded } =
  noteReducer.actions
export const isStrategyLoading = (state: RootState) => state.strategies.isLoading
export const biddingStrategiesSelector = (state: RootState) => state.strategies.strategies

export const defaultStrategySelector = (state: RootState) =>
  state.strategies.strategies.find(({ isDefault }) => isDefault)

export const tenantStrategies = (state: RootState) => {
  const strategies = state.strategies.strategies

  return {
    [TBiddingStrategy.CPC]: strategies.find(({ bidStrategy }) => bidStrategy === TBiddingStrategy.CPC),
    [TBiddingStrategy.MAX_CLICKS]: strategies.find(({ bidStrategy }) => bidStrategy === TBiddingStrategy.MAX_CLICKS),
    [TBiddingStrategy.CPM]: strategies.find(({ bidStrategy }) => bidStrategy === TBiddingStrategy.CPM),
    default: strategies.find(({ isDefault }) => isDefault),
  }
}

export const getTenantBudgetStrategy = (tenantId: string | number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const result = await TenantStrategyServiceAPI.get(tenantId)
      dispatch(putStrategies(result))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to get tenant strategy', e)
    }
  }
}

export const updateDefaultStrategy = (strategy: IStrategy) => {
  return async (dispatch: AppDispatch) => {
    try {
      if (!strategy.isDefault) {
        dispatch(strategyLoading())

        await TenantStrategyServiceAPI.updateDefaultStrategy(strategy.id)

        dispatch(updateStrategies(strategy.id))
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to update strategy', e)
      dispatch(setSnackbar(true, 'error', 'Fehler beim Aktualisieren'))
    }
  }
}

export const updateValueStrategy = (strategy: IStrategy) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(strategyLoading())
      dispatch(updateStrategyValue(strategy))

      await TenantStrategyServiceAPI.updateStrategyValue(strategy.id, strategy)

      dispatch(strategyLoaded())
    } catch (error) {
      dispatch(strategyLoaded())
      // eslint-disable-next-line no-console
      console.log('Failed to update strategy', error)
      dispatch(setSnackbar(true, 'error', 'Fehler beim Aktualisieren'))
    }
  }
}
