import 'internationalization/internationalization'

import CssBaseline from '@material-ui/core/CssBaseline'
import ErrorBoundary from 'common/components/ErrorBoundaryInside'
import ThemeProviderWrapper from 'common/components/ThemeProviderWrapper'
import pageHistory from 'core/history'
import store from 'core/store/store'
import translate from 'i18next'
import 'normalize.css'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import './analytics'
import AppWrapper from './common/components/AppWrapper'
import ErrorHandingModal from './common/components/ErrorHandling'
import { useLocales } from './core/router/hooks/useLocales'
import AppearanceLoader from './pages/Appearance/AppearanceLoader'
import TokenChecker from './core/router/utils/TokenChecker'
import ServiceDeskWidget from './libs/atlassian'
import useGoogleMaps from './libs/google'
import HistoryItemsProvider from './core/router/utils/HistoryItemsProvider'
import useKeyboardListener from './libs/hooks/useKeyboardListener'
import RouterContent from './core/router/routes'
import Snackbars from './common/components/Snackbar'

const Root = () => {
  useGoogleMaps()
  useLocales()
  useKeyboardListener()

  const AppContent = (
    <Router history={pageHistory}>
      <ServiceDeskWidget />
      <Snackbars />
      <AppWrapper>
        <RouterContent />
        <ErrorHandingModal />
      </AppWrapper>
    </Router>
  )

  return (
    <Provider store={store}>
      <I18nextProvider i18n={translate}>
        <HistoryItemsProvider>
          <AppearanceLoader>
            <TokenChecker>
              <ThemeProviderWrapper>
                <CssBaseline />
                {process.env.NODE_ENV === 'development' ? AppContent : <ErrorBoundary>{AppContent}</ErrorBoundary>}
              </ThemeProviderWrapper>
            </TokenChecker>
          </AppearanceLoader>
        </HistoryItemsProvider>
      </I18nextProvider>
    </Provider>
  )
}

export default Root
