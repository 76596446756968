import { PrivateRoute } from 'core/router/utils/PrivateRoute'
import AdServersLoader from 'pages/AdServers'
import CampaignManagement from 'pages/Campaigns/legacy/view'
import IssueReportButton from '../../pages/Campaigns/common/Components/IssueReportButton'
import {lazy, useMemo} from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, matchPath } from 'react-router-dom'

import { LazyLoading } from './utils/LazyLoading'
import { getCurrentLocalePath } from './hooks/useLocales'
import { selectedTenantSelector } from '../store/reducers/dropdownTenants'

const AUTH_ROUTES = {
  LOGIN: '/login',
  REGISTRATION: '/auth/registration',
  INVALID_TOKEN: '/auth/invalid-token',
  PASSWORD_CHANGE: '/auth/change-password',
  PASSWORD_RECOVERY: '/auth/recovery-password',
  SUCCESS_RECOVERY: '/auth/recovery-successful',
  EMAIL_CONFIRMATION: '/auth/confirm-email/:token',
  EMAIL_CONFIRMATION_INVALID_TOKEN: '/auth/confirm-email-failed',
  EMAIL_CONFIRMED: '/auth/confirm-email/success',
  REQUEST_EMAIL_CONFIRMATION_TOKEN: '/auth/confirm-email/request-token/:email',
  EMAIL_CONFIRMATION_TOKEN_REQUESTED: '/auth/request-token/success/:email',
}

const PRIVATE_ROUTES = {
  ROOT: '/',
  PROFILE: '/profile',
  PROFILE_PASSWORD_CHANGE: '/profile/password-change',
  CREATE_CAMPAIGN: '/campaigns/new',
  VIEW_CAMPAIGN: '/campaigns/view/:id',
  EDIT_CAMPAIGN: '/campaigns/edit/:id',
  COPY_CAMPAIGN: '/campaigns/copy/:id',
  CAMPAIGNS: '/campaigns',
  APPEARANCE: '/appearance',
  TENANT_APPEARANCE: '/tenants/:id/appearance',
  USERS: '/users',
  USER_EDIT: '/users/:id/edit/',
  CLIENTS: '/clients',
  CLIENT_EDIT: '/clients/:id/edit',
  CLIENT_CREATE: '/clients/new',
  TENANTS: '/tenants',
  TENANTS_EDIT: '/tenants/:id/edit',
  TENANT_CREATE: '/tenants/new',
  TENANT_SET_AD_SERVER: '/tenants/set-ad-server/:tenantId',
  TENANTS_BUDGETS: '/tenants/:tenantId/budgets',
  HELP: '/help',
}

const PUBLIC_ROUTES = {
  NOT_FOUND: '/404',
}

export const isAuthRoute = (route: string) => isRouteContained(route, AUTH_ROUTES)
export const isPrivateRoute = (route: string) => isRouteContained(route, PRIVATE_ROUTES)

const isRouteContained = (route: string, collection: Record<string, string>) => {
  return Object.values(collection).some((configRoute) => {
    return matchPath(route.replaceAll(getCurrentLocalePath(), ''), {
      path: configRoute,
      exact: true,
      strict: false,
    })
  })
}

export const Routes = {
  ...PRIVATE_ROUTES,
  ...AUTH_ROUTES,
  ...PUBLIC_ROUTES,
}


const EditCampaign = lazy(() => import( 'pages/Campaigns/legacy/edit'))
const CopyCampaign = lazy(() => import( 'pages/Campaigns/legacy/copy'))
const DesktopCampaignWizard = lazy(() => import( 'pages/Campaigns/new'))
const Campaigns = lazy(() => import('pages/Campaigns/list'))
const PasswordRecovery = lazy(() => import('pages/Auth/PasswordRecovery'))
const SuccessRecovery = lazy(() => import('pages/Auth/PasswordRecovery/SuccessRecovery'))
const Appearance = lazy(() => import('pages/Appearance/edit'))
const NotFound = lazy(() => import('common/components/NotFound'))
const Users = lazy(() => import('pages/Users/list'))
const Clients = lazy(() => import('pages/Clients/list'))
const ClientEdit = lazy(() => import('pages/Clients/edit'))
const UserEdit = lazy(() => import('pages/Users/edit'))
const TenantAppearance = lazy(() => import('pages/Tenants/appearance'))
const Login = lazy(() => import('pages/Auth/Login'))
const Registration = lazy(() => import('pages/Auth/Registration'))
const PasswordChange = lazy(() => import('pages/Auth/PasswordChange'))
const TokenRequestedSuccessfully = lazy(() => import('pages/Auth/EmailConfirmation/TokenRequestedSuccessfully'))
const RequestToken = lazy(() => import('pages/Auth/EmailConfirmation/RequestConfirmation'))
const EmailConfirmed = lazy(() => import('pages/Auth/EmailConfirmation/EmailConfirmed'))
const EmailConfirmation = lazy(() => import('pages/Auth/EmailConfirmation/ConfirmationPage'))
const ChangePassword = lazy(() => import('pages/Profile/ChangePassword'))
const ProfileSettings = lazy(() => import('pages/Profile/ProfileSettings'))
const InvalidToken = lazy(() => import('pages/Auth/PasswordChange/InvalidToken'))
const HelpPage = lazy(() => import('pages/Help'))
const ClientCreate = lazy(() => import('pages/Clients/new'))
const SetAdServer = lazy(() => import('pages/Tenants/adServer'))
const TenantCreate = lazy(() => import('pages/Tenants/new'))
const TenantsBudgets = lazy(() => import('pages/Tenants/budgets'))
const TenantEdit = lazy(() => import('pages/Tenants/edit'))
const Tenants = lazy(() => import('pages/Tenants/list'))
const EmailConfirmationInvalidToken = lazy(() => import('pages/Auth/EmailConfirmation/InvalidToken'))

const RouterContent = () => {
  let locale = getCurrentLocalePath()
  const selectedTenant = useSelector(selectedTenantSelector)
  const campaignDefaultQueryParams = useMemo(
    () => `?itemsPerPage=10&order[createdAt]=desc&page=1${selectedTenant ? `&tenant[]=${selectedTenant.id}` : ''}`,
    [selectedTenant]
  )
  const encodedCampaignParams = useMemo(() => encodeURI(campaignDefaultQueryParams), [campaignDefaultQueryParams])

  return (
    <Switch>
      <Route
        path={`${locale}` + Routes.EMAIL_CONFIRMATION_TOKEN_REQUESTED}
        render={() => LazyLoading(TokenRequestedSuccessfully)}
      />
      <Route
        exact
        path={`${locale}` + Routes.REQUEST_EMAIL_CONFIRMATION_TOKEN}
        render={() => LazyLoading(RequestToken)}
      />
      <Route path={`${locale}` + Routes.EMAIL_CONFIRMED} render={() => LazyLoading(EmailConfirmed)} />
      <Route path={`${locale}` + Routes.EMAIL_CONFIRMATION} render={() => LazyLoading(EmailConfirmation)} />
      <Route
        path={`${locale}` + Routes.EMAIL_CONFIRMATION_INVALID_TOKEN}
        exact
        render={() => LazyLoading(EmailConfirmationInvalidToken)}
      />
      <Route path={`${locale}` + Routes.PASSWORD_CHANGE} render={() => LazyLoading(PasswordChange)} />
      <Route path={`${locale}` + Routes.INVALID_TOKEN} render={() => LazyLoading(InvalidToken)} />
      <Route exact path={`${locale}` + Routes.LOGIN} render={() => LazyLoading(Login)} />
      <Route exact path={`${locale}` + Routes.REGISTRATION} render={() => LazyLoading(Registration)} />
      <Route exact path={`${locale}` + Routes.PASSWORD_RECOVERY} render={() => LazyLoading(PasswordRecovery)} />
      <Route exact path={`${locale}` + Routes.SUCCESS_RECOVERY} render={() => LazyLoading(SuccessRecovery)} />
      <PrivateRoute exact path={`${locale}` + Routes.PROFILE_PASSWORD_CHANGE}>
        {LazyLoading(ChangePassword)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.TENANT_SET_AD_SERVER}>
        {LazyLoading(SetAdServer)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.TENANTS_BUDGETS}>
        {LazyLoading(TenantsBudgets)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.TENANTS}>
        {LazyLoading(Tenants)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.PROFILE}>
        {LazyLoading(ProfileSettings)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.ROOT}>
        <Redirect to={`${locale}` + Routes.CAMPAIGNS + encodedCampaignParams} />
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.CREATE_CAMPAIGN}>
        <AdServersLoader>
          {LazyLoading(DesktopCampaignWizard)}
        </AdServersLoader>
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.VIEW_CAMPAIGN}>
        <IssueReportButton>
          <CampaignManagement/>
        </IssueReportButton>
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.EDIT_CAMPAIGN}>
        {LazyLoading(EditCampaign)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.COPY_CAMPAIGN}>
        {LazyLoading(CopyCampaign)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.USERS}>
        {LazyLoading(Users)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.USER_EDIT}>
        {LazyLoading(UserEdit)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.CLIENTS}>
        {LazyLoading(Clients)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.CLIENT_CREATE}>
        {LazyLoading(ClientCreate)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.CLIENT_EDIT}>
        {LazyLoading(ClientEdit)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.CAMPAIGNS}>
        {LazyLoading(Campaigns)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}${Routes.TENANTS}/:id/appearance`}>
        {LazyLoading(TenantAppearance)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}${Routes.TENANT_CREATE}`}>
        {LazyLoading(TenantCreate)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}${Routes.TENANTS}/:id/edit`}>
        {LazyLoading(TenantEdit)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.APPEARANCE}>
        {LazyLoading(Appearance)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.REGISTRATION}>
        {LazyLoading(Registration)}
      </PrivateRoute>
      <PrivateRoute exact path={`${locale}` + Routes.HELP}>
        {LazyLoading(HelpPage)}
      </PrivateRoute>
      <Route exact path={`${locale}`} render={() => <Redirect to={`${locale}` + Routes.ROOT} />} />
      <Route exact path={`${locale}` + Routes.NOT_FOUND} render={() => LazyLoading(NotFound)} />
      <Route render={() => LazyLoading(NotFound)} />
    </Switch>
  )
}

export default RouterContent