import { ReactComponent as CampainIcon } from 'assets/icons/campain_icon.svg'
import { ReactComponent as ClientsIcon } from 'assets/icons/clients_icon.svg'
import { ReactComponent as TenantsIcon } from 'assets/icons/tenants_icon.svg'
import { ReactComponent as UsersIcon } from 'assets/icons/users_icon.svg'
import { loggedUserRoleSelector } from 'core/store/reducers/auth'
import { selectedTenantSelector } from 'core/store/reducers/dropdownTenants'
import { Roles } from 'core/store/reducers/userReducer'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Routes } from 'core/router/routes'

import { NavigationButton } from './Button'
import {useTranslation} from "react-i18next";

interface NavigationButtonProps {
  text: string
  route: string
  icon: any
  allowedOnlyFor?: Array<Roles>
  queryParams?: string
}

export const NavigationButtons = () => {
  const loggedUserRole = useSelector(loggedUserRoleSelector)
  const selectedTenant = useSelector(selectedTenantSelector)
  const { t } = useTranslation()

  const config: Array<NavigationButtonProps> = useMemo(
    () => [
      {
        text: t('header.navigation.campaign'),
        route: Routes.CAMPAIGNS,
        icon: CampainIcon,
        queryParams: `?itemsPerPage=10&order[createdAt]=desc&page=1${
          selectedTenant ? `&tenant[]=${selectedTenant.id}` : ''
        }`,
      },
      {
        text: t('header.navigation.client'),
        icon: ClientsIcon,
        route: Routes.CLIENTS,
        queryParams: `?itemsPerPage=10&order[createdAt]=desc&page=1${
          selectedTenant ? `&tenant[]=${selectedTenant.id}` : ''
        }`,
      },
      {
        text: t('header.navigation.user'),
        icon: UsersIcon,
        route: Routes.USERS,
        queryParams: `?itemsPerPage=10&order[createdAt]=desc&page=1${
          selectedTenant ? `&tenant[]=${selectedTenant.id}` : ''
        }`,
      },
      {
        text: t('header.navigation.tenants'),
        icon: TenantsIcon,
        route: Routes.TENANTS,
        allowedOnlyFor: [Roles.superadmin],
        queryParams: '?itemsPerPage=10&order[createdAt]=desc&page=1',
      },
    ],
    [selectedTenant]
  )

  const buttons = config.map(({ route, text, icon, allowedOnlyFor, queryParams }) => {
    const Component = <NavigationButton key={route} queryParams={queryParams} link={route} text={text} icon={icon} />

    if (!allowedOnlyFor || allowedOnlyFor?.includes(loggedUserRole)) {
      return Component
    } else {
      return <></>
    }
  })

  return <>{buttons}</>
}
