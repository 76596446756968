import { Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@mui/material'
import translate from 'i18next'
import type { FC } from 'react'
import React, { useState } from 'react'
// @ts-ignore
import { useTranslation } from 'react-i18next'

import { filterByType } from '../../advertising/native/schema'
import type { Device } from '../banner/images'
import DesktopHorizontal from './DesktopHorizontal'
import DesktopSquare from './DesktopSquare'
import MobileHorizontal from './MobileHorizontal'
import MobileSquare from './MobileSquare'
import type {EnhancedCampaign} from '../../../../../../core/type'
import type {IImage} from 'common/components/Images/types'
import { IMAGE_TYPE} from 'common/components/Images/types'

interface INativePreview {
  deviceType: Device
  imageType: IMAGE_TYPE.HORIZONTAL | IMAGE_TYPE.SQUARE
  campaign: EnhancedCampaign
  activeImageIndex: number
}

const ResolveNativePreview = ({ deviceType, imageType, campaign, activeImageIndex }: INativePreview) => {
  if (deviceType === 'desktop' && imageType === IMAGE_TYPE.SQUARE) {
    return <DesktopSquare campaign={campaign} activeImageIndex={activeImageIndex} />
  } else if (deviceType === 'desktop' && imageType === IMAGE_TYPE.HORIZONTAL) {
    return <DesktopHorizontal campaign={campaign} activeImageIndex={activeImageIndex} />
  } else if (deviceType === 'mobile' && imageType === IMAGE_TYPE.SQUARE) {
    return <MobileSquare campaign={campaign} activeImageIndex={activeImageIndex} />
  } else if (deviceType === 'mobile' && imageType === IMAGE_TYPE.HORIZONTAL) {
    return <MobileHorizontal campaign={campaign} activeImageIndex={activeImageIndex} />
  } else {
    alert('Could not handle Native preview')
  }
}

const styles = makeStyles(() => ({
  label: {
    fontSize: 20,
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.88)',
    marginBottom: 10,
  },
}))

const usePreviewStyles = makeStyles({
  previewContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    width: 417,
    flexWrap: 'wrap',
    gap: 8,
  },
  horizontalBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 69,
    width: 125,
  },
  squareBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 92,
    height: 92,
  },
  imageSquare: {
    height: 84,
    width: 84,
    borderRadius: 4,
    background: 'url(<path-to-image>), lightgray 50% / cover no-repeat',
    border: '1px solid var(--grey-100, #D8DAD9)',
    cursor: 'pointer',
  },
  imageSquareActive: {
    height: 92,
    width: 92,
    background: 'url(<path-to-image>), white 50% / cover no-repeat',
    cursor: 'pointer',
    padding: 2,
    borderRadius: 8,
    border: '2px solid  #149600',
  },
  imageHorizontal: {
    height: 61,
    width: 117,
    borderRadius: 4,
    background: 'url(<path-to-image>), lightgray 50% / cover no-repeat',
    border: '1px solid var(--grey-100, #D8DAD9)',
    cursor: 'pointer',
  },
  imageHorizontalActive: {
    height: 69,
    width: 125,
    background: 'url(<path-to-image>), white 50% / cover no-repeat',
    cursor: 'pointer',
    padding: 2,
    borderRadius: 8,
    border: '2px solid  #149600',
  },
})

interface Props {
  images: IImage[]
  imageType: IMAGE_TYPE.HORIZONTAL | IMAGE_TYPE.SQUARE
  setActiveImageIndex: (index: number) => void
  activeImageIndex: number
}

const SelectableImages: FC<Props> = ({ images, imageType, setActiveImageIndex, activeImageIndex }) => {
  const classes = usePreviewStyles()
  const containerClass = imageType === 'square' ? classes.squareBox : classes.horizontalBox
  const getImageClass = (index: number) => {
    if (imageType === 'square') {
      return activeImageIndex === index ? classes.imageSquareActive : classes.imageSquare
    } else {
      return activeImageIndex === index ? classes.imageHorizontalActive : classes.imageHorizontal
    }
  }

  return (
    <>
      {filterByType(images, imageType).map((image, index) => {
        return (
          <Box key={index} className={containerClass}>
            <img
              className={getImageClass(index)}
              src={image.url}
              alt='preview'
              onClick={() => setActiveImageIndex(index)}
            />
          </Box>
        )
      })}
    </>
  )
}

export const PreviewContent: FC<{ campaign: EnhancedCampaign }> = ({ campaign }) => {
  const { label } = styles()
  const { t } = useTranslation()
  const { devices, images } = campaign
  const classes = usePreviewStyles()
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0)
  const [deviceType, setDeviceType] = useState<Device>(devices.desktop ? 'desktop' : 'mobile')
  const [imageType, setImageType] = useState<IMAGE_TYPE.HORIZONTAL | IMAGE_TYPE.SQUARE>(IMAGE_TYPE.HORIZONTAL)

  const handleImageTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageType((event.target as HTMLInputElement).value as IMAGE_TYPE.HORIZONTAL | IMAGE_TYPE.SQUARE)
  }
  const handleDeviceTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeviceType((event.target as HTMLInputElement).value as Device)
  }

  return (
    <Box>
      <Grid container spacing={2} justifyContent={'flex-start'} direction={'row'}>
        <Grid item xs={6}>
          <Grid container direction='column' spacing={6}>
            <Grid item>
              <FormControl component='fieldset'>
                <FormLabel component='legend' className={label}>
                  {t('campaigns.preview.selectDevice')}
                </FormLabel>
                <RadioGroup row aria-label='device' name='device' value={deviceType} onChange={handleDeviceTypeChange}>
                  <FormControlLabel
                    value='mobile'
                    disabled={!devices.mobile}
                    control={<Radio color='primary' />}
                    label={t('campaigns.preview.mobile')}
                  />
                  <FormControlLabel
                    value='desktop'
                    disabled={!devices.desktop}
                    control={<Radio color='primary' />}
                    label={t('campaigns.preview.desktop')}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl component='fieldset'>
                <FormLabel component='legend' className={label}>
                  {t('campaigns.preview.selectImageSize')}
                </FormLabel>
                <RadioGroup
                  row
                  aria-label='image-size'
                  name='image-size'
                  value={imageType}
                  onChange={handleImageTypeChange}
                >
                  <FormControlLabel value={IMAGE_TYPE.HORIZONTAL} control={<Radio color='primary' />} label='1.91:1' />
                  <FormControlLabel value={IMAGE_TYPE.SQUARE} control={<Radio color='primary' />} label='1:1' />
                </RadioGroup>
              </FormControl>
              <Typography style={{ marginTop: 32, fontWeight: 600, fontSize: 20 }}>
                {t('campaigns.preview.selectImages')}
              </Typography>
              <Box className={classes.previewContainer}>
                <SelectableImages
                  images={images}
                  imageType={imageType}
                  setActiveImageIndex={setActiveImageIndex}
                  activeImageIndex={activeImageIndex}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {ResolveNativePreview({ deviceType, imageType, campaign, activeImageIndex })}
        </Grid>
      </Grid>
    </Box>
  )
}

export const WizardNativeStep = (campaign: EnhancedCampaign) => {
  return {
    isSavedOnPrev: true,
    Content: <PreviewContent campaign={campaign} />,
    isValid: true,
    isSubmitting: false,
    isValidating: false,
    errors: {},
    label: translate.t('campaigns.preview.preview'),
    submit: () => Promise.resolve(true),
    dirty: false,
    isOptional: true,
    resetForm: () => Promise.resolve(),
    isShown: true,
  }
}

export const CampaignNativeTab: FC<{ campaign: EnhancedCampaign }> = ({ campaign }) => (
  <PreviewContent campaign={campaign} />
)
