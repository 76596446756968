import Axios from '../../core/axios'
import type { UserGender, UserI, UserStatus } from '../../core/store/reducers/userReducer'
import { formRequestUrl } from '../utils/requestsUtils'
import type {IUserPreferences, LDApiReturnValue, RequestParamsI} from './types'

export class UsersApi {
  static async logout(): Promise<void> {
    await Axios.delete('tokens/current', {
      headers: { Accept: 'application/json' },
    })
  }

  static async changeOwnPassword(password: any): Promise<void> {
    await Axios.put(
      'user',
      { password },
      {
        headers: { Accept: 'application/json' },
      }
    )
  }

  static async deleteSelf(): Promise<void> {
    return Axios.delete('user', {
      headers: { Accept: 'application/json' },
    })
  }

  static async updateSelf(user: Pick<UserI, 'gender' | 'name'>): Promise<Pick<UserI, 'gender' | 'name'>> {
    const { data } = await Axios.put('user', user, {
      headers: { Accept: 'application/json' },
    })

    return data as Pick<UserI, 'gender' | 'name'>
  }

  static async updateSelfPreferences(preferences: IUserPreferences): Promise<IUserPreferences> {
    const { data } = await Axios.put('user/preferences', preferences, {
      headers: { Accept: 'application/json' },
    })

    return data as IUserPreferences
  }

  static async getSelfPreferences(): Promise<IUserPreferences> {
    const { data } = await Axios.get('user/preferences', {
      headers: { Accept: 'application/json' },
    })

    return data as IUserPreferences
  }

  static async getSelf(): Promise<UserI> {
    const { data } = await Axios.get('user', {
      headers: { Accept: 'application/json' },
    })

    data.preferences = await this.getSelfPreferences()

    return data as UserI
  }

  static async updateOnePreferences(userId: string | number, preferences: IUserPreferences): Promise<UserI> {
    const { data } = await Axios.put(`users/${userId}/preferences`, preferences, {
      headers: { Accept: 'application/ld+json' },
    })

    return data as UserI
  }
  static async getOnePreferences(userId: string | number): Promise<UserI> {
    const { data } = await Axios.get(`users/${userId}/preferences`, {
      headers: { Accept: 'application/ld+json' },
    })

    return data as UserI
  }

  static async getOne(userId: string | number): Promise<UserI> {
    const { data } = await Axios.get(`users/${userId}`, {
      headers: { Accept: 'application/ld+json' },
    })

    data.preferences = await this.getOnePreferences(userId)

    return data as UserI
  }

  static async getMany(params: RequestParamsI): Promise<LDApiReturnValue<UserI>> {
    const { data } = await Axios.get(formRequestUrl('users', params), {
      headers: { Accept: 'application/ld+json' },
    })

    return {
      data: data['hydra:member'],
      meta: {
        totalItems: data['hydra:totalItems'],
      },
    }
  }

  static async updateUserStatus(userId: string | number, newStatus: UserStatus) {
    const { data } = await Axios.put(`users/${userId}`, {
      status: newStatus,
    })

    return data.status
  }

  static async update({ gender, name, role, id }: UserI) {
    const { data } = await Axios.put(`users/${id}`, {
      gender,
      name,
      role,
    })

    return data
  }

  static async create({
    gender,
    name,
    password,
    email,
  }: {
    password: string
    gender: UserGender
    name: string
    email: string
  }) {
    const { data } = await Axios.post('users', {
      gender,
      name,
      password,
      email,
    })

    return data
  }
}
