import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { LDApiReturnValue, RequestParamsI } from 'api/users/types'
import { UsersApi } from 'api/users/users'
import type { AppDispatch, RootState } from 'core/store/store'
import { tenantsPathNamesSelector } from 'core/store/reducers/dropdownTenants'

import type { UserI, UserStatus } from './userReducer'

const initialState = {
  users: new Array<UserI>(),
  isLoading: false,
  meta: {
    totalItems: 0,
  },
}

export const usersReducer = createSlice({
  name: 'users',
  initialState,
  reducers: {
    usersLoading: (state) => {
      state.isLoading = true
    },
    usersSuccess: (state, { payload }) => {
      const { data, meta } = payload as LDApiReturnValue<UserI>

      state.isLoading = false
      state.users = data
      state.meta = meta
    },
    usersFailure: (state) => {
      state.isLoading = false
    },
    updateStatus: (state, { payload }) => {
      state.isLoading = false
      const { id, status } = payload

      state.users = state.users.map((user) => {
        if (user.id === id) {
          user.status = status
        }

        return user
      })
    },
    updateFailed: (state) => {
      state.isLoading = false
    },
  },
})

export default usersReducer.reducer

const { usersFailure, usersLoading, usersSuccess, updateStatus, updateFailed } = usersReducer.actions

export const usersSelector = (state: RootState) => state.users.users
export const usersMetaInfoSelector = (state: RootState) => state.users.meta
export const isUsersLoadingSelector = (state: RootState) => state.users.isLoading

export const usersWithTenants = createSelector(
  usersSelector,
  tenantsPathNamesSelector,
  (clients, tenantsHash: { [key: string]: string }) => {
    return clients.map(({ tenant, ...rest }) => {
      const tenantName = tenantsHash[tenant] || tenant

      return { ...rest, tenant: tenantName }
    })
  }
)

export const updateUserStatus = (userId: number, newStatus: UserStatus) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(usersLoading())

      const updateResult = await UsersApi.updateUserStatus(userId, newStatus)

      dispatch(
        updateStatus({
          id: userId,
          status: updateResult,
        })
      )
    } catch (e) {
      dispatch(updateFailed())
      // eslint-disable-next-line no-console
      console.log('Failed to update user', e)
    }
  }
}

export const getUsers = (queryParams: RequestParamsI) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(usersLoading())

      const ldResponse = await UsersApi.getMany(queryParams)

      dispatch(usersSuccess(ldResponse))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to query users', e)

      dispatch(usersFailure())
    }
  }
}
