import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { RootState } from 'core/store/store'
import type { Roles, UserI } from 'core/store/reducers/userReducer'

import { campaignSelector } from './campaign'
import { selectedTenantSelector } from './dropdownTenants'

interface IUserState {
  user: UserI
  errors: string | null
  isLoading: boolean
}

const initialState: IUserState = {
  user: {} as UserI,
  errors: null,
  isLoading: false,
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLoading: (state) => {
      state.isLoading = true
      state.errors = null
    },
    userSuccess: (state, { payload }) => {
      state.errors = null
      state.isLoading = false
      state.user = payload
    },
    userError: (state, { payload }) => {
      state.isLoading = false
      state.errors = payload
    },
    userUpdate: (state, { payload }) => {
      state.user = { ...state.user, ...payload }
      state.isLoading = false
    },
  },
})

export const { userLoading, userSuccess, userError, userUpdate } = auth.actions

export const logoutAction = () => ({ type: 'LOGOUT' })

export const loggedUserSelector = (state: RootState): UserI => state.auth.user

export const loggedUserRoleSelector = (state: RootState): Roles => state.auth.user.role

export const isUserLoadingSelector = (state: RootState) => state.auth.isLoading

export const getOwnTenantIdSelector = (state: RootState) => {
  const tenant = state.auth.user.tenant

  return tenant ? tenant!.match(/\d+$/)![0] : ''
}

export const getOwnTenantSelector = (state: RootState) => {
  return state.auth.user.tenant
}

export const isOwnTenantSelectedSelector = createSelector(
  getOwnTenantIdSelector,
  selectedTenantSelector,
  (authId, tenantId) => +authId === tenantId?.id
)

export const isTenantCompanySelectedSelector = createSelector(
  getOwnTenantSelector,
  campaignSelector,
  selectedTenantSelector,
  (ownTenant, campaign, selectedTenant) => {
    if (!ownTenant && !selectedTenant) {
      return true
    }
    //// superadmin
    if (ownTenant !== campaign.tenant) {
      return false
    }

    return true
  }
)

export default auth.reducer
