/* eslint-disable no-console */
import type { ILocation } from 'common/components/Maps'
import axios from 'core/axios'
import type { Suggestion } from 'react-places-autocomplete'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import { CampaignAPI } from '../campaigns/campaign'
import { MapsApi } from '../maps/maps'
import { LocationsWorker } from './LocationsWorker'
import type {CoordinatesCache} from './types'

class LocationService {
  coordinatesCache = new Map<string, CoordinatesCache>()

  getLocationDetails = async (location: ILocation): Promise<ILocation> => {
    const { address, enteredAddress } = location

    const addressToRequest = enteredAddress! || address

    let locationCoordinates: CoordinatesCache

    if (this.coordinatesCache.has(addressToRequest)) {
      locationCoordinates = this.coordinatesCache.get(addressToRequest)!
    } else {
      const { coordinates, displayAs } = await MapsApi.getLocationPolygons(addressToRequest)
      const [geocode] = await geocodeByAddress(addressToRequest)
      const { lat, lng } = await getLatLng(geocode)

      const fallbackAreaCoordinates = coordinates?.length === 0 ? [[{ lat, lng }]] : coordinates!

      locationCoordinates = {
        coordinates: fallbackAreaCoordinates,
        lat,
        lng,
        displayAs,
      }

      this.coordinatesCache.set(addressToRequest, locationCoordinates)
    }

    return {
      ...location,
      address: address || enteredAddress!,
      enteredAddress: enteredAddress || address,
      ...locationCoordinates,
    }
  }

  getNewLocationDetails = async (
    suggestion: Suggestion,
    addressFromSearch: string
  ): Promise<Omit<ILocation, 'campaign' | 'type' | 'id'>> => {
    const [geocode] = await geocodeByAddress(addressFromSearch)
    const enteredAddress = suggestion.description
    const address = this.isZip(geocode) ? this.formatAddressZip(geocode) : suggestion.description

    const addressToRequest = address || enteredAddress
    let locationCoordinates: CoordinatesCache

    if (this.coordinatesCache.has(addressToRequest)) {
      locationCoordinates = this.coordinatesCache.get(addressToRequest)!
    } else {
      const { coordinates, displayAs } = await MapsApi.getLocationPolygons(addressToRequest)

      const { lat, lng } = await getLatLng(geocode)

      const fallbackAreaCoordinates = coordinates?.length === 0 ? [[{ lat, lng }]] : coordinates!

      locationCoordinates = {
        coordinates: fallbackAreaCoordinates,
        lat,
        lng,
        displayAs,
      }

      this.coordinatesCache.set(addressToRequest, locationCoordinates)
    }

    return {
      ...locationCoordinates,
      address,
      enteredAddress,
    }
  }

  create = async (location: ILocation): Promise<ILocation> => {
    try {
      let locationToSubmit

      if (location.type === 'address') {
        locationToSubmit = {
          campaign: location.campaign,
          type: 'address',
          address: location.address,
          enteredAddress: location.enteredAddress,
          longitude: null,
          latitude: null,
          radius: null,
        }
      } else {
        locationToSubmit = {
          campaign: location.campaign,
          type: 'point',
          address: null,
          enteredAddress: location.enteredAddress,
          longitude: location.lng,
          latitude: location.lat,
          radius: location.radius,
        }
      }

      const { data } = await axios.post('locations', locationToSubmit)

      return data
    } catch (error) {
      console.log('error while creating location')

      return Promise.reject(error)
    }
  }

  update = async (location: ILocation): Promise<ILocation> => {
    try {
      const { data } = await axios.put(`locations/${location.id}`, location)

      return data
    } catch (error) {
      console.log('error while updating location')

      return Promise.reject(error)
    }
  }

  get = async (id: string): Promise<Array<ILocation>> => {
    try {
      const { data } = await axios.get(`campaigns/${id}/locations`, {
        headers: { Accept: 'application/json' },
      })

      return data
    } catch (error) {
      console.log('error while getting location')

      return Promise.reject(error)
    }
  }

  delete = async (locationId: number): Promise<ILocation> => {
    try {
      const { data } = await axios.delete(`locations/${locationId}`, {
        headers: { Accept: 'application/json' },
      })

      return data
    } catch (error) {
      console.log('error while getting location')

      return Promise.reject(error)
    }
  }

  recreateAddresses = async (campaignId: number, locations: Array<ILocation>): Promise<Array<ILocation>> => {
    const companyLocations = await CampaignAPI.getCampaignLocations(campaignId)
    const newLocationsWithCampaignId = locations.map((location) => ({
      ...location,
      campaign: campaignId,
    }))

    await LocationsWorker.recreate({
      locationsToCreate: newLocationsWithCampaignId,
      locationsToDelete: companyLocations,
    })

    return CampaignAPI.getCampaignLocations(campaignId)
  }

  locationDeserializer = async (location: ILocation): Promise<ILocation> => {
    try {
      if (location.type === 'address') {
        const addressLocation = {
          type: 'address',
          address: location.address,
          enteredAddress: location.enteredAddress,
          longitude: null,
          latitude: null,
          radius: null,
        }
        const { data } = await axios.put(`locations/${location.id}`, addressLocation) // TODO JOIN ALL TO SINGLE OBJECT

        return data
      } else {
        const pointLocation = {
          type: 'point',
          address: null,
          enteredAddress: location.enteredAddress,
          longitude: location.lng,
          latitude: location.lat,
          radius: location.radius,
        }
        const { data } = await axios.put(`locations/${location.id}`, pointLocation)

        return data
      }
    } catch (error) {
      console.log('error while updating location')

      return Promise.reject(error)
    }
  }

  private isZip = (geocode: google.maps.GeocoderResult) => {
    return geocode.types.includes('postal_code')
  }

  private formatAddressZip = ({ address_components }: google.maps.GeocoderResult) => {
    const zipInfo = address_components[0]
    const countryInfo = address_components.find((component) => component.types.includes('country'))
    const city = address_components[1]

    return `${zipInfo.short_name} ${city.long_name}, ${countryInfo?.long_name}`
  }
}

export const LocationAPI = new LocationService()
