import { createSlice } from '@reduxjs/toolkit'
import { CampaignAPI } from 'api/campaigns/campaign'
import { ClientsApi } from 'api/clients/clients'
import type { AppDispatch, RootState } from 'core/store/store'
import type { Countries, TenantI } from 'core/store/reducers/dropdownTenants'

export interface ClientI {
  '@id': string
  id: number
  externalId: string
  name: string
  createdAt: string
  updatedAt: string
  email: string
  countryCode: Countries
  zip: string
  city: string
  address: string
  phone: string
  registrationNumber: string
  tradeLicenceNumber: string
  vatNumber: string
  dunsNumber: string
  tenant?: Partial<TenantI> // admin does not have it
  files: Array<UploadedDocument>
}

export enum FileType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  ZIP = 'application/zip',
  MOZILLA_ZIP = 'application/x-zip-compressed',
}

export enum ClientFileType {
  VAT = 'vat',
  COMMERCIAL_REGISTER = 'commercialRegister',
  TRADE_LICENCE = 'tradeLicense',
}

export interface UploadedDocument {
  id: number | null
  type: ClientFileType
  file: File
  url: string
  name: string
}

interface IClientState {
  client: ClientI
  isLoading: boolean
  error: string
}

const initialState: IClientState = {
  client: {
    '@id': '',
    id: -1,
    name: '',
    updatedAt: '',
    createdAt: '',
    externalId: '',
    registrationNumber: '',
    address: '',
    dunsNumber: '',
    city: '',
    zip: '',
    countryCode: '' as any,
    email: '',
    tradeLicenceNumber: '',
    vatNumber: '',
    phone: '',
    tenant: {
      id: 0,
      '@id': '0',
      name: '',
      domain: '',
      email: '',
      googleAdsCustomerId: '',
      countryCode: '' as any,
      zip: '',
      city: '',
      address: '',
      phone: '',
      createdAt: '',
      updatedAt: '',
      adServers: {
        seedingAlliance: 0,
        googleAds: 0,
      },
    },
    files: [],
  },
  isLoading: false,
  error: '',
}

const clientReducer = createSlice({
  name: 'client',
  initialState,
  reducers: {
    clientLoading: (state) => {
      state.isLoading = true
    },
    clientSuccess: (state, { payload }) => {
      state.isLoading = false
      state.client = { ...state.client, ...payload }
    },
    clientFailure: (state) => {
      state.isLoading = false
    },
    clientReset: () => initialState,
  },
})

export const { clientFailure, clientLoading, clientSuccess, clientReset } = clientReducer.actions

export const clientIsLoadingSelector = (state: RootState) => state.client.isLoading
export const clientSelector = (state: RootState) => state.client.client

export const getClientByCampaignId = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(clientLoading())

    try {
      const data = await CampaignAPI.getClientByCampaignId(id)

      dispatch(clientSuccess(data))
    } catch (error) {
      dispatch(clientFailure())

      // eslint-disable-next-line no-console
      console.log('Failed to query client', error)
    }
  }
}

export const getClient = (clientId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(clientLoading())

      const client = await ClientsApi.getOne(clientId)

      dispatch(clientSuccess(client))
    } catch (error) {
      dispatch(clientFailure())

      // eslint-disable-next-line no-console
      console.log('Failed to query client', error)
    }
  }
}

export default clientReducer.reducer
