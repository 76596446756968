import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    top: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#252B27',
    height: 56,
    zIndex: theme.zIndex.drawer + 1,
  },
  container: {
    maxWidth: 1200,
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  selectorConteiner: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  secondaryDimmed: {
    color: 'rgba(255, 255, 255, 0.74)',
  },
  menuItem: {
    height: 32,
  },
  whiteColor: {
    color: theme.palette.common.white,
  },
  userMenuContainer: {
    cursor: 'pointer',
    '&:hover': {
      // background: theme.palette.secondary[500],
      '& svg:first-child': {
        fill: '#8B918D',
      },
    },
  },
  activeUserMenu: {
    // background: theme.palette.secondary[500],
    '& svg:first-child': {
      fill: '#8B918D',
    },
  },
  userName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 160,
    whiteSpace: 'nowrap',
    color: theme.palette.common.white,
  },
  customLogo: {
    maxHeight: 24,
  },
}))

export default useStyles