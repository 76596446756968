import { TextField } from '@material-ui/core'
import type { TextFieldProps } from '@material-ui/core/TextField/TextField'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import type { FormikProps, useFormik } from 'formik'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'

import { InputCounter } from '../InputCounter'

export const makeInputLabelStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: '16px !important',
    paddingRight: 5,
    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  outlined: {
    transform: 'translate(14px, 18px) scale(1)',
  },
  disabled: {
    color: `${theme.palette.secondary[400]} !important`,
  },
}))

export const makeHelperTextClasses = makeStyles(() => ({
  root: {
    marginTop: 4,
    fontSize: 12,
  },
}))

export const makeInputStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 48,
    fontSize: 16,
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&.Mui-disabled': {
      background: theme.palette.secondary[50],
      color: 'rgb(0, 0, 0)',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px !important',
    },
  },
}))

export const makeMultilineInputStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
    padding: '14px !important',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&.Mui-disabled': {
      background: theme.palette.secondary[50],
      color: 'rgb(0, 0, 0)',
    },
  },
}))

export type FormikInputHelpers =
  | Pick<
      ReturnType<typeof useFormik>,
      'handleChange' | 'setFieldTouched' | 'touched' | 'errors' | 'setFieldValue' | 'setErrors' | 'values'
    >
  | Pick<
      FormikProps<any>,
      'handleChange' | 'setFieldTouched' | 'touched' | 'errors' | 'setFieldValue' | 'setErrors' | 'values'
    >

export type FormikInputProps = Partial<TextFieldProps> & {
  formik: FormikInputHelpers
  isEditable?: boolean
  name: string
  errorField?: string
  setTouchedOnChange?: boolean
  counter?: boolean
  touchOnInit?: boolean
  isValidatedWhenAutofocusBlurred?: boolean
  trim?: boolean
  value: any
  helperText?: string
  maxLength?: number
  extendableToMultiline?: boolean
  highlightError?: boolean
  allowZeroValue?: boolean
}

const MAX_ONE_LINE_SYMBOLS = 65

export const FormikMaterialInput = (props: FormikInputProps) => {
  const inputLabelClasses = makeInputLabelStyles()
  const inputClasses = makeInputStyles()
  const multilineInputClasses = makeMultilineInputStyles()
  const helperTextClasses = makeHelperTextClasses()

  const [blurs, setBlurs] = useState<number>(0)

  const {
    fullWidth = true,
    variant = 'outlined',
    name,
    value,
    type,
    label,
    placeholder,
    InputProps,
    helperText,
    errorField = name,
    autoComplete,
    disabled,
    setTouchedOnChange = false,
    counter = false,
    maxLength,
    autoFocus = false,
    isValidatedWhenAutofocusBlurred = false,
    touchOnInit = false,
    required,
    formik,
    inputRef,
    trim,
    extendableToMultiline = false,
    multiline,
    highlightError = false,
    allowZeroValue = false,
    ...rest
  } = props

  const { handleChange, setFieldTouched, touched, errors, setFieldValue } = formik

  useEffect(() => {
    if (touchOnInit && !touched[name]) {
      setFieldTouched(name, true)
    }
  })

  const extendableInputProps = useMemo(() => {
    if (extendableToMultiline) {
      return {
        multiline: true,
        rows: Math.ceil(value?.length / MAX_ONE_LINE_SYMBOLS),
      }
    } else {
      return {}
    }
  }, [value, extendableToMultiline])

  const errorMsg = _.at(errors, errorField).at(0)
  const fieldTouched = _.at(touched, name).at(0)

  const helperTextMemo = useMemo(
    () =>
      (maxLength && counter && (
        <InputCounter
          helperText={(fieldTouched && errorMsg) || helperText}
          currentLength={formik.values[name]?.trim()?.length}
          maxLength={maxLength}
        />
      )) ||
      (fieldTouched && errorMsg) ||
      helperText,
    [helperText, formik, errors, touched, maxLength, counter]
  )

  const fieldValue = (valueField: any) => {
    if (allowZeroValue && props.type === 'number' && valueField === 0) {
      return 0
    } else {
      return valueField || ''
    }
  }

  return (
    <TextField
      {...rest}
      {...extendableInputProps}
      InputLabelProps={{
        classes: inputLabelClasses,
      }}
      FormHelperTextProps={{
        classes: helperTextClasses,
      }}
      InputProps={{
        ...InputProps,
        classes: multiline || extendableToMultiline ? multilineInputClasses : inputClasses,
        autoFocus,
        inputRef,
      }}
      required={required}
      autoComplete={autoComplete || 'off'}
      label={label}
      type={type}
      placeholder={placeholder}
      fullWidth={fullWidth}
      variant={variant}
      value={fieldValue(value)}
      onBlur={() => {
        setBlurs((blurs) => blurs + 1)

        if (autoFocus && isValidatedWhenAutofocusBlurred && blurs < 1) {
          return
        }

        setFieldTouched(name, true)
      }}
      id={name}
      onChange={(e) => {
        if (setTouchedOnChange) {
          setFieldTouched(name, true)
        }

        if (trim) {
          setFieldValue(name, e.target.value.trim())
        } else {
          handleChange(e)
        }
      }}
      error={(fieldTouched && !!errorMsg) || highlightError}
      helperText={helperTextMemo}
      disabled={disabled}
    />
  )
}
