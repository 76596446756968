import type { NoteComment } from '../../../../../api/note/types'
import { NotesAPI } from '../../../../../api/note/note'

export class NoteService {
  update = async (campaignId: number, note: NoteComment) => {
    const allNotes = await NotesAPI.get(campaignId)

    if (!allNotes.length) {
      if (!note.comment) {
        return []
      }

      return NotesAPI.post(campaignId, note)
    }

    if (!note.comment) {
      await NotesAPI.delete(allNotes[0].id)

      return []
    }

    await NotesAPI.delete(allNotes[0].id)

    return await NotesAPI.post(campaignId, note)
  }

  get = async (campaignId: number) => {
    return NotesAPI.get(campaignId)
  }
}

export const NoteServiceAPI = new NoteService()
