import { FormControlLabel, Grid, Radio, RadioGroup, Slider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import translator from 'i18next'
import React from 'react'
import { useSelector } from 'react-redux'
import type * as Yup from 'yup'

import type { TCampaignType } from '../../../../../core/type'
import { Genders } from '../../../../../core/type'
import { DynamicStep } from '../../../common/Components/FormGenerator/FormBuilder'
import { campaignSelector } from '../../../../../core/store/reducers/campaign'

export const targetingFormStyles = makeStyles(() => ({
  slider: {
    '& .MuiSlider-valueLabel > span': {
      width: 29,
      height: 24,
      transform: 'scale(1) translateY(60px)',
      borderRadius: 4,
    },
    '& .MuiSlider-valueLabel > span > span': {
      transform: 'none',
    },
  },
}))

export const TargetingView = (campaignType: TCampaignType | null) => {
  const campaign = useSelector(campaignSelector)
  const { slider } = targetingFormStyles()

  return DynamicStep({
    label: translator.t('campaigns.adSettings'),
    dependencies: [campaign.type],
    fieldsOrder: [],
    fieldsAdditionalProps: {},
    additionalValidation: {
      gender: (genderValidations) =>
        (genderValidations as Yup.StringSchema).oneOf([Genders.men, Genders.women, Genders.all]).default(Genders.all),
    },
    schemaRequestUrl: `schemas/Target${
      campaignType ? `/${campaignType}` : ''
    }?adServers=all&type=output&operationType=item`,
    additionalQueryParams: {
      subtype: campaign.adAttachmentType,
    },
    initialValues: {
      genders: campaign.gender,
      startAge: campaign.age.startAge,
      endAge: campaign.age.endAge,
    },
    fieldsToOmit: ['type', 'subtype', 'campaign', 'name'],
    translationsKey: '',
    declareRequired: ['genders', 'endAge', 'startAge'],
    renderFunction: (props) => (formik) => {
      return (
        <>
          {props.some((prop) => prop.name === 'genders') && (
            <Grid item xs={6}>
              <Typography variant='subtitle1'>{translator.t('common.gender.gender')}</Typography>
              <RadioGroup
                row
                name='genders'
                aria-label='genders'
                value={formik.values.genders}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  disabled={true}
                  value={Genders.all}
                  control={<Radio disabled={true} checked={formik.values.genders === Genders.all} color='primary' />}
                  label={<Typography variant='body1'>{translator.t('common.gender.all')}</Typography>}
                />
                <FormControlLabel
                  value={Genders.men}
                  control={<Radio disabled={true} checked={formik.values.genders === Genders.men} color='primary' />}
                  label={<Typography variant='body1'>{translator.t('common.gender.men')}</Typography>}
                />
                <FormControlLabel
                  value={Genders.women}
                  control={<Radio disabled={true} checked={formik.values.genders === Genders.women} color='primary' />}
                  label={<Typography variant='body1'>{translator.t('common.gender.women')}</Typography>}
                />
              </RadioGroup>
            </Grid>
          )}

          {props.some((prop) => prop.name === 'startAge') && (
            <Grid item xs={6}>
              <Typography variant='subtitle1'>{translator.t('common.age.age')}</Typography>
              <Slider
                disabled={true}
                min={18}
                max={70}
                className={slider}
                value={[formik.values.startAge, formik.values.endAge]}
                valueLabelFormat={(value) => `${value >= 65 ? '65+' : value}`}
                onChange={(_: React.ChangeEvent<Record<string, never>>, newAge: number[] | number) => {
                  const [startAge, endAge] = newAge as [number, number]

                  formik.setFieldValue('startAge', startAge)
                  formik.setFieldValue('endAge', endAge)
                }}
                valueLabelDisplay='on'
                aria-labelledby='range-slider'
              />
            </Grid>
          )}
        </>
      )
    },
    onSubmit: () => async () => true,
  })
}
