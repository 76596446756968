import type { Ad, AdAttachmentType } from 'core/type'
import { TCampaignType } from 'core/type'
import axios from 'core/axios'

class AdService {
  create = async (ad: Partial<Ad>): Promise<Ad> => {
    try {
      const { data } = await axios.post('ads', { ...ad })

      return data
    } catch (error) {
      console.log('error while creating ads :(')

      return Promise.reject(error)
    }
  }

  update = async (id: number, ad: Partial<Ad>): Promise<Ad> => {
    try {
      const { data } = await axios.put(`ads/${id}`, { ...ad })

      return data
    } catch (error) {
      console.log('error while updating ad')

      return Promise.reject(error)
    }
  }

  get = async (id: string) => {
    try {
      const { data } = await axios.get(`campaigns/${id}/ads `, {
        headers: { Accept: 'application/json' },
      })

      return data
    } catch (error) {
      console.log('error while getting ads')

      return Promise.reject(error)
    }
  }

  getFiles = async (id: string | number) => {
    try {
      const { data } = await axios.get(`ads/${id}/files `, {
        headers: { Accept: 'application/json' },
      })

      return data
    } catch (error) {
      console.log('error while getting ads')

      return Promise.reject(error)
    }
  }

  updateSubtype = async (adSubtype: AdAttachmentType, adId: string | number) => {
    try {
      const { data } = await axios.put(
        `ads/${adId}`,
        { subtype: adSubtype },
        {
          headers: { Accept: 'application/json' },
        }
      )

      return data
    } catch (error) {
      console.log('error while updateing subtype')

      return Promise.reject(error)
    }
  }

  createFromValues = async (ad: Partial<Ad>, newCampaignId: number, dynamicValues: Partial<Ad>): Promise<Ad> => {
    try {
      let newAd: Partial<Ad>

      if (ad.type === TCampaignType.banner) {
        newAd = {
          url: dynamicValues.url,
          campaign: newCampaignId,
          type: TCampaignType.banner,
          subtype: ad.subtype,
          name: 'Ad1',
        }
      } else if (ad.type === TCampaignType.native) {
        newAd = {
          url: dynamicValues.url,
          headline: dynamicValues.headline,
          description: dynamicValues.description,
          businessName: dynamicValues.businessName,
          campaign: newCampaignId,
          type: TCampaignType.native,
          subtype: ad.subtype,
          name: 'Ad1',
        }
      } else {
        throw Error('no such type')
      }

      return this.create(newAd)
    } catch (error) {
      console.log('error while creating ads :(')

      return Promise.reject(error)
    }
  }
}

export const AdAPI = new AdService()
