import { createSlice } from '@reduxjs/toolkit'
import { TenantAppearanceAPI } from 'api/tenantAppearance/tenantAppearance'
import { ThemeNames, getTheme } from 'core/palette'
import type { AppDispatch, RootState } from 'core/store/store'
import type { Appearance, CustomLogo } from 'core/store/reducers/appearance'

export const initialState: Appearance & CustomLogo & { isLoading: boolean } = {
  theme: ThemeNames.green,
  baseColor: getTheme(ThemeNames.green).palette.primary.main,
  isPoweredBy: false,
  isCustomLogo: false,
  customLogoUrl: '',
  isLoading: false,
}

const tenantAppearance = createSlice({
  name: 'tenantAppearance',
  initialState,
  reducers: {
    updateTenantTheme: (state, { payload }) => {
      const { theme, baseColor } = payload
      state.baseColor = baseColor
      state.theme = theme
    },
    tenantAppearanceLoading: (state) => {
      state.isLoading = true
    },
    tenantAppearanceError: (state) => {
      state.isLoading = false
    },
    tenantAppearanceSuccess: (state, { payload }) => {
      const { theme, baseColor, isPoweredBy, isCustomLogo } = payload
      state.isLoading = false
      state.baseColor = baseColor
      state.theme = theme
      state.isPoweredBy = isPoweredBy
      state.isCustomLogo = isCustomLogo
    },
    updateTenantCustomLogo: (state, { payload }) => {
      state.customLogoUrl = payload
    },
  },
})

export default tenantAppearance.reducer

export const {
  tenantAppearanceSuccess,
  updateTenantCustomLogo,
  updateTenantTheme,
  tenantAppearanceError,
  tenantAppearanceLoading,
} = tenantAppearance.actions

export const tenantAppearanceSelector = (state: RootState) => state.tenantAppearance

export const getTenantAppearance = (tenantId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(tenantAppearanceLoading())

      const appearance = await TenantAppearanceAPI.getTenantAppearance(tenantId)
      const logo = await TenantAppearanceAPI.getTenantLogo(tenantId)

      dispatch(tenantAppearanceSuccess(appearance))

      if (logo) {
        dispatch(updateTenantCustomLogo(logo.url))
      }
    } catch (e) {
      dispatch(tenantAppearanceError())
      // eslint-disable-next-line no-console
      console.log('Failed to update tenant appearance', e)
    }
  }
}
