import { isBefore } from 'date-fns'

import type { Campaign, EnhancedCampaign } from '../../core/type'
import { PackagesAPI } from './packages'
import type {Package} from './types'

export class SubscriptionService {
  static create = async (campaignForUpdate: EnhancedCampaign, campaignToUpdateId: number) => {
    const { client, isSubscriptionActive, previousCampaign } = campaignForUpdate

    const packages = await PackagesAPI.getClientPackagesWithCampaigns(client)

    const selectedCampaignPackage = packages.find((pack) => {
      return pack.campaigns.some(({ id }) => previousCampaign.id === id)
    })

    const currentCampaignPackage = packages.find((pack) => {
      return pack.campaigns.some((campaign) => campaign.id === campaignToUpdateId)
    })

    if (isSubscriptionActive) {
      if (previousCampaign.id === null && currentCampaignPackage) {
        // campaign is already in a package, but we select new subscription, do nothing here for now
      } else if (
        selectedCampaignPackage &&
        currentCampaignPackage &&
        selectedCampaignPackage === currentCampaignPackage
      ) {
        // same package selected, do nothing here
      } else if (previousCampaign.id === null) {
        if (!currentCampaignPackage) {
          const newPackage = await PackagesAPI.createNew(client)
          //create new package
          await PackagesAPI.addCampaignToPackage(newPackage.id, campaignToUpdateId)
        }
      } else if (selectedCampaignPackage && !currentCampaignPackage) {
        await PackagesAPI.addCampaignToPackage(selectedCampaignPackage.id, campaignToUpdateId)
      } else if (
        selectedCampaignPackage &&
        currentCampaignPackage &&
        selectedCampaignPackage !== currentCampaignPackage
      ) {
        await PackagesAPI.removeCampaignFromPackage(currentCampaignPackage.id, campaignToUpdateId)
        await PackagesAPI.addCampaignToPackage(selectedCampaignPackage.id, campaignToUpdateId)
      }
    } else {
      // check it was
      // delete if it was
      if (currentCampaignPackage) {
        await PackagesAPI.removeCampaignFromPackage(currentCampaignPackage.id, campaignToUpdateId)
      }
    }
  }

  static update = async (campaignForUpdate: EnhancedCampaign, campaignToUpdateId: number) => {
    const { packages, previousCampaign, isSubscriptionActive, client } = campaignForUpdate

    const selectedCampaignPackage = packages.find((pack: Package) => {
      return pack.campaigns.some(({ id }) => previousCampaign.id === id)
    })

    const currentCampaignPackage = packages.find((pack: Package) => {
      return pack.campaigns.some((packCampaign) => packCampaign.id === campaignToUpdateId)
    })
    if (isSubscriptionActive) {
      if (previousCampaign.id === null && currentCampaignPackage) {
        // campaign is already in a package, but we select new subscription, do nothing here for now
      } else if (
        selectedCampaignPackage &&
        currentCampaignPackage &&
        selectedCampaignPackage === currentCampaignPackage
      ) {
        // same package selected, do nothing here
      } else if (previousCampaign.id === null) {
        if (!currentCampaignPackage) {
          const newPackage = await PackagesAPI.createNew(client)
          //create new package
          await PackagesAPI.addCampaignToPackage(newPackage.id, campaignToUpdateId)
        }
      } else if (selectedCampaignPackage && !currentCampaignPackage) {
        await PackagesAPI.addCampaignToPackage(selectedCampaignPackage.id, campaignToUpdateId)
      } else if (
        selectedCampaignPackage &&
        currentCampaignPackage &&
        selectedCampaignPackage !== currentCampaignPackage
      ) {
        await PackagesAPI.removeCampaignFromPackage(currentCampaignPackage.id, campaignToUpdateId)
        await PackagesAPI.addCampaignToPackage(selectedCampaignPackage.id, campaignToUpdateId)
      }
    }
  }

  static getInfo = async (clientId: string | number, campaignId: number) => {
    const packages = await PackagesAPI.getClientPackagesWithCampaigns(clientId)
    const campaignPackage = packages.find((pack) => pack.campaigns.some((campaign) => campaign.id === campaignId))

    let previousCampaign: Campaign = {
      id: null,
    } as any as Campaign

    let nextCampaign: Campaign = {
      id: null,
    } as any as Campaign

    if (campaignPackage && campaignPackage.campaigns.length > 1) {
      const campaign = campaignPackage.campaigns.find((campaign) => campaign.id === campaignId) as Campaign

      const campaignsBefore = campaignPackage.campaigns.filter(
        ({ endDate, id }) => isBefore(new Date(endDate!), new Date(campaign.startDate!)) && id !== campaignId
      )

      const campaignsAfter = campaignPackage.campaigns.filter(
        ({ startDate, id }) => isBefore(new Date(campaign.endDate!), new Date(startDate!)) && id !== campaignId
      )

      if (campaignsBefore.length > 0) {
        previousCampaign = campaignsBefore[campaignsBefore.length - 1]
      }

      if (campaignsAfter.length > 0) {
        nextCampaign = campaignsAfter[0]
      }
    }

    return {
      isSubscriptionActive: !!campaignPackage,
      packages,
      previousCampaign,
      nextCampaign,
    }
  }
}
