import Axios from '../../core/axios'
import type {Package} from './types'


export class PackagesAPI {
  static async getClientPackagesWithCampaigns(clientId: string | number): Promise<Array<Package>> {
    const { data } = await Axios.get(`clients/${clientId}/packages?groups[]=campaigns&pagination=false`)

    const { 'hydra:member': packages } = data

    return packages
  }

  static async createNew(clientId: string | number): Promise<Package> {
    const { data } = await Axios.post('packages', {
      client: clientId,
    })

    return data
  }

  static async addCampaignToPackage(packageId: string | number, campaignId: string | number): Promise<Package> {
    const { data } = await Axios.post(`packages/${packageId}/items`, {
      campaign: campaignId,
    })

    return data
  }

  static async removeCampaignFromPackage(packageId: string | number, campaignId: string | number): Promise<Package> {
    const { data } = await Axios.delete(`packages/${packageId}/items/${campaignId}`)

    return data
  }
}
