import { Grid, Typography } from '@material-ui/core'
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded'
import { makeStyles } from '@material-ui/styles'
import translator from 'i18next'
import type { ErrorInfo } from 'react'
import React, { Component } from 'react'

const useStyles = makeStyles({
  root: { height: '100vh' },
  color: {
    color: 'rgba(0, 0, 0, 0.4)',
  },
  icon: {
    width: 111,
    height: 96,
    color: 'rgba(0, 0, 0, 0.4)',
  },
})

interface IState {
  hasError: boolean
}

class ErrorBoundary extends Component<React.ComponentClass, IState> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.log({ error, errorInfo })
  }

  render() {
    const { hasError } = this.state

    return hasError ? <NotFoundPage /> : this.props.children
  }
}

export const NotFoundPage = () => {
  const classes = useStyles()

  return (
    <Grid className={classes.root} container justifyContent='center' alignItems='center' direction='column'>
      <ReportProblemRoundedIcon className={classes.icon} />
      <Typography className={classes.color} variant='h4' gutterBottom>
        {translator.t('errorBoundary.siteWasNotFound')}
      </Typography>
      <Typography className={classes.color} variant='body1'>
        {translator.t('errorBoundary.pageDoesNotExist')}
      </Typography>
    </Grid>
  )
}

export default ErrorBoundary
