import { Box } from '@material-ui/core'
import type { FormikInputProps } from 'common/components/Inputs/FormikMaterialInput'
import Axios from 'core/axios'
import type { FormikHelpers } from 'formik'
import { useFormik } from 'formik'
import type { ReactNode } from 'react'
import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'

import type { ValidationStep } from '../Stepper/linear'
import { SchemaParser } from './SchemaParser'

interface FormBuilderProps {
  schema: Yup.AnySchema
  content: (formikInstance: ReturnType<typeof useFormik>) => ReactNode | null
  initialValues: Record<string, any>
  onSubmit: ReturnType<AsyncStepProps['onSubmit']>
}

interface SchemaBuilderProps {
  setLoading: (isLoading: boolean) => void
  setRenderContent: (props: FormBuilderProps) => void
  schemaRequestUrl: string
}

export interface AsyncStepProps {
  label: string
  dependencies: Array<any>
  schemaRequestUrl: string
  fieldsToOmit?: Array<string>
  declareRequired?: Array<string>
  additionalQueryParams?: Record<string, any>
  initialValues: Record<any, any>
  fieldsOrder: Array<string>

  fieldsAdditionalProps?: Record<
    string,
    Partial<FormikInputProps> | Record<string, (...args: Array<any>) => any> | Record<string, any>
  >
  translationsKey: string
  additionalValidation: Record<
    string,
    (existingValidations: Yup.StringSchema | Yup.NumberSchema | Yup.ObjectSchema<any>) => any
  >
  onSubmit: (
    props: AsyncStepProps['additionalQueryParams']
  ) => (values: Record<string, any>, helpers: FormikHelpers<Record<string, any>>) => Promise<boolean>
  renderFunction: (
    inputProps: Array<Partial<FormikInputProps>>,
    translationsKey: AsyncStepProps['translationsKey']
  ) => (formik: ReturnType<typeof useFormik>) => ReactNode
}

const schemaLoader = async ({
  setRenderContent,
  setLoading,
  schemaRequestUrl,
  fieldsToOmit,
  additionalQueryParams,
  initialValues: campaignValues,
  onSubmit,
  additionalValidation,
  fieldsOrder = [],
  renderFunction,
  fieldsAdditionalProps,
  translationsKey,
  declareRequired,
}: SchemaBuilderProps & AsyncStepProps) => {
  try {
    const { data: apiSchema } = await Axios.get(schemaRequestUrl, {
      headers: { Accept: 'application/json' },
    })

    const proceededApiSchema = SchemaParser.preprocessSchema(apiSchema, fieldsToOmit, declareRequired)
    const schema = SchemaParser.buildValidationSchema(proceededApiSchema, additionalValidation, translationsKey)
    const initialValues = SchemaParser.buildInitialValues(proceededApiSchema, campaignValues)
    const markupProps = SchemaParser.buildMarkupProps(apiSchema, fieldsOrder, fieldsAdditionalProps)

    setRenderContent({
      schema,
      content: renderFunction(markupProps, translationsKey),
      initialValues,
      onSubmit: onSubmit(additionalQueryParams),
    })
    setLoading(false)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Failed to get schema by URL ${schemaRequestUrl}. Original error below.`)
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

const useDynamicFormBuilder = (props: FormBuilderProps | null) => {
  const stubSchema = Yup.object()
  const stubSubmit = () => {
    return Promise.resolve(true)
  }

  const defaultInitialValues = {}

  const canInitialBeValid = (schema: Yup.AnySchema, initialValues: Record<string, any>): boolean => {
    if (schema && initialValues) {
      try {
        return schema.validateSync(initialValues)
      } catch (e: any) {
        return false
      }
    }

    return false
  }

  const isInitialValid = useMemo(() => canInitialBeValid(props?.schema!, props?.initialValues!), [props])

  const formik = useFormik({
    initialValues: props?.initialValues || defaultInitialValues,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    isInitialValid,
    validationSchema: props?.schema || stubSchema,
    validateOnMount: true,
    onSubmit: props?.onSubmit ? props.onSubmit : stubSubmit,
  })

  const { isValid, isSubmitting, isValidating, errors, dirty, submitForm } = formik

  const resetForm = async () => {
    formik.resetForm()
    await formik.validateForm()
  }

  return {
    Content: (props?.content && props.content(formik)) || <></>,
    resetForm,
    isValid,
    isSubmitting,
    isValidating,
    errors,
    dirty,
    submit: submitForm,
    formik,
  }
}

export const DynamicStep = (
  props: AsyncStepProps
): ValidationStep & {
  formik: ReturnType<typeof useFormik>
  dynamicContentLoading: boolean
} => {
  const [result, setResult] = useState<FormBuilderProps | null>(null)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    // prettier-ignore
    (async () => {
      await schemaLoader({
        ...props,
        setRenderContent: setResult,
        setLoading,
      })
    })()
  }, props.dependencies)

  const dynamicContent = useDynamicFormBuilder(result)

  return {
    label: props.label,
    isValid: false,
    isValidating: false,
    isSubmitting: false,
    Content: <Box width={'100%'} height={'200px'} position={'relative'}></Box>,
    errors: {},
    submit: () => Promise.resolve(false),
    dirty: false,
    isSavedOnPrev: true,
    isOptional: false,
    resetForm: () => Promise.resolve(),
    ...(!isLoading && result ? dynamicContent : {}),
    formik: dynamicContent.formik,
    dynamicContentLoading: isLoading,
    isShown: true,
  }
}
