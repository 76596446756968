const useKeyboardListener = () => {
  window.addEventListener('keydown', function (e) {
    // @ts-ignore // custom attribute which prevents scroll on space key
    if ((e.keyCode === 32 || e.key === ' ') && e.target?.dataset?.deletable) {
      e.preventDefault()
    }
  })
}


export default useKeyboardListener