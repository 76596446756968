import Axios from 'core/axios'
import type { TenantI } from 'core/store/reducers/dropdownTenants'

import type { LDApiReturnValue, RequestParamsI } from '../users/types'
import { formRequestUrl } from '../utils/requestsUtils'
import type {TenantCreateParams} from './types'

const serializeTenant = (tenant: Partial<TenantI>) => {
  return {
    ...tenant,
    ...(tenant.googleAdsCustomerId
      ? {
          googleAdsCustomerId: tenant.googleAdsCustomerId.replaceAll('-', ''),
        }
      : {}),
  }
}

export class TenantAPI {
  static getCurrent = async () => {
    try {
      const { data } = await Axios.get('/tenant', {
        headers: { Accept: 'application/json' },
      })

      return data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while fetching current tenant')
    }
  }

  static getOne = async (id: number | string): Promise<TenantI> => {
    try {
      const { data } = await Axios.get(`/tenants/${id}?groups[]=adServers`, {
        headers: { Accept: 'application/ld+json' },
      })

      return data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while fetching tenant')

      return Promise.reject(error)
    }
  }

  static getMany = async (
    params: RequestParamsI
  ): Promise<{
    tenants: Array<TenantI>
    totalItems: number
  }> => {
    try {
      const { data } = await Axios.get(formRequestUrl('tenants', params), {
        headers: { Accept: 'application/ld+json' },
      })

      return {
        tenants: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while getting tenants')

      return Promise.reject(error)
    }
  }

  static getAll = async (): Promise<LDApiReturnValue<TenantI>> => {
    try {
      const { data } = await Axios.get('tenants?pagination=false&order[id]=asc')
      const { 'hydra:member': tenants, 'hydra:totalItems': totalItems } = data

      return {
        data: tenants,
        meta: {
          totalItems,
        },
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while fetching tenants', error)

      return Promise.reject(error)
    }
  }

  static async create(values: TenantCreateParams) {
    try {
      const { data } = await Axios.post('tenants', serializeTenant(values))

      return data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while saving tenant', error)

      return Promise.reject(error)
    }
  }
  static async update({ id, ...rest }: Partial<TenantI>) {
    try {
      return Axios.put(`tenants/${id}`, serializeTenant(rest))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while fetching tenant', error)

      return Promise.reject(error)
    }
  }

  static async delete(id: string | number) {
    try {
      return Axios.delete(`tenants/${id}`)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while deleting tenant', error)

      return Promise.reject(error)
    }
  }

  static async getTenantBudgets(tenantId: string | number) {
    const { data } = await Axios.get(`/budgets?tenant=${tenantId}&order[budget]=asc&pagination=false`)
    const { 'hydra:member': budgets } = data

    return budgets
  }

  static async getTenantTargetImpressions(tenantId: string | number) {
    const { data } = await Axios.get(`/target-impressions?tenant=${tenantId}&order[budget]=asc&pagination=false`)
    const { 'hydra:member': targetImpressions } = data

    return targetImpressions
  }
}
