import { Box, Grid, Slider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { throttle } from 'lodash'
import type { FC } from 'react'
import React, { useMemo, useState } from 'react'

import { sliderDistance } from './util'

const targetingFormStyles = makeStyles(() => ({
  slider: {
    '& .MuiSlider-valueLabel': {
      left: 0,
    },
    '& .MuiSlider-valueLabel > span': {
      width: 48,
      height: 24,
      transform: 'scale(1) translate(-19px, 60px)',
      borderRadius: 4,
    },
    '& .MuiSlider-valueLabel > span > span': {
      transform: 'none',
    },
  },
}))

export const RadiusChooseSlider: FC<{ onChange: (newValue: number) => void; value: number; disabled?: boolean }> = ({
  value,
  onChange,
  disabled = false,
}) => {
  const [radius, setRadius] = useState<number>(value)
  const { slider } = targetingFormStyles()

  const throttledSetRadius = useMemo(() => throttle(setRadius, 200), [setRadius])

  return (
    <Box>
      <Slider
        className={slider}
        min={sliderDistance('min')}
        max={sliderDistance('max')}
        step={1}
        value={radius}
        disabled={disabled}
        onChange={(e, newLocation) => {
          onChange(newLocation as number)
          throttledSetRadius(newLocation as number)
        }}
        valueLabelDisplay='on'
        aria-labelledby='radius-select-slider'
        getAriaValueText={(value) => (value + ' km') as any}
        valueLabelFormat={(value) => (value + ' km') as any}
      />
      <Grid container justifyContent='space-between'>
        <Typography variant='caption'>{sliderDistance('min')} km</Typography>
        <Typography variant='caption'>{sliderDistance('max')} km</Typography>
      </Grid>
    </Box>
  )
}
