import { createSlice } from '@reduxjs/toolkit'
import { TenantAPI } from 'api/tenants/tenants'
import { setSnackbar } from 'core/store/reducers/snackbar'
import type { AppDispatch, RootState } from 'core/store/store'
import type { TenantI } from 'core/store/reducers/dropdownTenants'

const initialState = {
  tenant: {
    id: 0,
    '@id': '0',
    name: '',
    domain: '',
    email: '',
    googleAdsCustomerId: '',
    countryCode: '' as any,
    zip: '',
    city: '',
    address: '',
    phone: '',
    createdAt: '',
    updatedAt: '',
    adServers: {
      seedingAlliance: 0,
      googleAds: 0,
    },
  } as TenantI,
  isLoading: false,
  error: '',
}

const appearance = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    tenantLoading: (state) => {
      state.isLoading = true
    },
    tenantReset: () => initialState,
    tenantSuccess: (state, { payload }) => {
      state.isLoading = false
      state.tenant = payload
    },
    tenantFailure: (state) => {
      state.isLoading = false
    },
  },
})

export default appearance.reducer

export const { tenantLoading, tenantReset, tenantSuccess, tenantFailure } = appearance.actions

export const tenantSelector = (state: RootState) => state.tenant.tenant
export const isTenantLoadingSelector = (state: RootState) => state.tenant.isLoading

export const getTenant = (tenantId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(tenantLoading())
      const tenant = await TenantAPI.getOne(tenantId)

      dispatch(tenantSuccess(tenant))
    } catch (e) {
      dispatch(tenantFailure())
      dispatch(setSnackbar(true, 'error', 'Failed to get tenant'))
    }
  }
}

export { initialState as TenantInitialState }
