import { Box, FormControl, InputLabel, MenuItem, Select, Typography, createStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { ExpandMoreRounded } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import {
  isTenantsLoadingSelector,
  selectedTenantSelector,
  setSelectedTenant,
  tenantsSelector,
} from 'core/store/reducers/dropdownTenants'
import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Routes } from '../../../../core/router/routes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #2B323F',
      },
    },
    focused: {},
    notchedOutline: {},
    formControl: {
      maxWidth: 212,
      '&:hover': {
        opacity: 1,
      },
      '&:focus-within': {
        opacity: 1,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #2B323F',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #2B323F',
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #2B323F',
      },
      '& .Mui-disabled': {
        opacity: 0.6,
      },
      '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #2B323F',
      },
    },
    label: {
      display: 'none',
    },
    tenantTextColor: {
      color: 'rgba(255, 255, 255, 0.74)',
    },
    tenantName: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: 160,
      whiteSpace: 'nowrap',
      color: theme.palette.common.white,
    },
  })
)

export const SuperadminTenantSelector: FC = () => {
  const { formControl, root, tenantTextColor, tenantName, label } = useStyles()
  const { search, pathname } = useLocation()

  const isLoading = useSelector(isTenantsLoadingSelector)
  const tenants = useSelector(tenantsSelector)
  const selectedTenant = useSelector(selectedTenantSelector)
  const dispatch = useDispatch()

  const queryParamsTenant = useMemo(() => {
    const tenant = new URLSearchParams(search).get('tenant[]')

    if (tenant) {
      return parseInt(tenant)
    }
  }, [search, isLoading])

  useEffect(() => {
    if (queryParamsTenant && !isLoading && tenants.length > 0) {
      dispatch(setSelectedTenant(queryParamsTenant))
    }
  }, [queryParamsTenant, isLoading])

  const name = 'Tenant'

  const handleTenantChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(setSelectedTenant(event.target.value as number))
  }

  const disabled = useMemo(
    () => ![Routes.CLIENTS, Routes.CAMPAIGNS, Routes.USERS].includes(pathname.replace(new RegExp('/(en|de)'), '')),
    [pathname]
  )

  return (
    selectedTenant && (
      <FormControl fullWidth size='small' className={formControl}>
        <InputLabel className={label} id={`label-${name}`} htmlFor={name}>
          Tenant
        </InputLabel>

        <Select
          disabled={disabled}
          variant='outlined'
          IconComponent={(props) => {
            return (
              <ExpandMoreRounded
                {...props}
                // eslint-disable-next-line react/prop-types
                className={clsx(tenantTextColor, props.className)}
              />
            )
          }}
          className={root}
          fullWidth
          id={name}
          name={name}
          labelId={`label-${name}`}
          data-testid={name}
          value={selectedTenant?.id}
          onChange={(e) => handleTenantChange(e)}
          renderValue={(selectedTenantId) => {
            const tenant = tenants.find((tenant) => tenant.id === selectedTenantId)

            return (
              <>
                <Typography className={tenantTextColor} variant='body1' component='span'>
                  Tenant:
                </Typography>
                <Box ml={2} component='span'>
                  <Typography className={tenantName} variant='subtitle1' component='span'>
                    {tenant && tenant!.name}
                  </Typography>
                </Box>
              </>
            )
          }}
        >
          {tenants.map(({ id, name }) => {
            return (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  )
}
