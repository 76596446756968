import { createSlice } from '@reduxjs/toolkit'
import { CampaignAPI } from 'api/campaigns/campaign'
import type { RequestParamsI } from 'api/users/types'
import type { Campaign } from 'core/type'
import type { AppDispatch, RootState } from 'core/store/store'

interface ICampaignsState {
  campaigns: Array<Campaign>
  totalItems: number
  errors: string | null
  isLoading: boolean
  isReportLoading: boolean
}

export const initialState: ICampaignsState = {
  campaigns: [],
  isLoading: false,
  totalItems: 0,
  errors: null,
  isReportLoading: false,
}

const campaigns = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    campaignsLoading: (state) => {
      state.isLoading = true
      state.errors = null
    },
    campaignsSuccess: (state, { payload }) => {
      state.campaigns = payload.campaigns
      state.totalItems = payload.totalItems
      state.isLoading = false
    },
    campaignsError: (state, { payload }) => {
      state.errors = payload
      state.isLoading = false
    },
    reportLoading: (state) => {
      state.isReportLoading = true
    },
    reportSuccess: (state) => {
      state.isReportLoading = false
    },
    reportFailed: (state) => {
      state.isReportLoading = false
    },
  },
})

export const { campaignsLoading, campaignsSuccess, campaignsError, reportLoading, reportFailed, reportSuccess } =
  campaigns.actions

export const isCampaignLoadingSelector = (state: RootState): boolean => state.campaigns.isLoading

export const campaignsSelector = (state: RootState): Array<Campaign> => state.campaigns.campaigns

export const campaignsTotalItemsSelector = (state: RootState): number => state.campaigns.totalItems

export const isReportLoadingSelector = (state: RootState): boolean => state.campaigns.isReportLoading

export default campaigns.reducer

export function downloadReport(campaignId: number, campaignName: string) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(reportLoading())

      await CampaignAPI.generateReport(campaignId, campaignName)

      dispatch(reportSuccess())
    } catch (error) {
      dispatch(reportFailed())
    }
  }
}

export function getCampaigns(requestParams: RequestParamsI) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(campaignsLoading())
      const result = await CampaignAPI.list(requestParams)

      dispatch(campaignsSuccess(result))
    } catch (error) {
      dispatch(campaignsError(error))
    }
  }
}
