import { Box, withStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { CampaignStatus } from 'core/type'
import React from 'react'
import {useTranslation} from "react-i18next";

interface TabPanelProps {
  name: string
  value: string
  [x: string]: any
}

export const TabPanel: React.FC<TabPanelProps> = ({ value, name, children, ...props }) => {
  return (
    <Box role='tabpanel' hidden={value !== name} id={`tabpanel-${name}`} aria-labelledby={`tab-${name}`} {...props}>
      {children}
    </Box>
  )
}

interface IStatusLabel {
  status: CampaignStatus
}

const labelStyles = (status: CampaignStatus, theme: Theme) => {
  switch (status) {
    case 'failed':
      return {
        background: '#FEE2E2',
        color: '#DC2626',
        border: '1px solid #FCA5A5',
      }

    case 'inReview':
      return {
        background: '#FEF0C7',
        color: '#B54708',
        border: '1px solid #FEC84B',
      }

    case 'ready':
      return {
        background: '#E0F2FE',
        color: '#026AA2',
        border: '1px solid #70C0E5',
      }

    case 'draft':
      return {
        background: '#EBE9FE',
        color: '#3E1C96',
        border: '1px solid #BDB4FE',
      }

    case 'active':
      return {
        background: '#D1F2CC',
        color: '#0F7100',
        border: '1px solid #19BC00',
      }

    case 'canceled':
      return {
        background: 'rgba(0, 0, 0, 0.04)',
        color: 'rgba(0, 0, 0, 0.60)',
        border: '1px solid rgba(0, 0, 0, 0.20)',
      }

    case 'completed':
      return {
        background: '#EDF2EE',
        color: '#66806C',
        border: '1px solid #CEDED2',
      }

    // case 'paused':
    //   return {
    //     background: theme.palette.warning.main,
    //     color: theme.palette.common.white
    //   };
    default:
      return {
        background: theme.palette.success[100],
        color: theme.palette.success[200],
      }
  }
}

export const StatusLabel: React.FC<IStatusLabel> = ({ status }) => {
  const { t } = useTranslation()
  const EnhancedBox = withStyles((theme: Theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 8px',
      borderRadius: 4,
      cursor: 'pointer',
      textTransform: 'capitalize',
      fontSize: 12,
      ...labelStyles(status as CampaignStatus, theme),
      letterSpacing: '0.4px',
      lineHeight: '14px',
    },
  }))(Box)

  return <EnhancedBox>{t(`campaigns.status.${status}`)}</EnhancedBox>
}
